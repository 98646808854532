/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
// Components
import SectionUI from '@shi/common/SectionUI';
import StyleConfig from '@shi/common/StyleConfig';
import FaqSection from '@shi/components/accordion/Faq';
import HeaderBanner from '@shi/components/banner/HeaderBanner';
import RegisterStickyButton from '@shi/components/button/RegisterStickyButton';
import Testimonials from '@shi/components/slider/Testimonials';
import Activities from '@shi/section/Activities';
import AttendInPerson from '@shi/section/AttendInPerson';
import ContactUs from '@shi/section/ContactUs';
import Donate from '@shi/section/Donate';
import Highlights from '@shi/section/Highlights';
import History from '@shi/section/History';
import Interested from '@shi/section/Interested';
import Schedule from '@shi/section/Schedule';
import ScheduleText from '@shi/section/ScheduleText';
import ShivangaPricing from '@shi/section/ShivangaPricing';
import UpcomingShivangaSpurthi from '@shi/section/UpcomingShivangaSpurthi';
import VidText from '@shi/section/VidText';
import Volunteer from '@shi/section/Volunteer';
import WhatIsShivanga from '@shi/section/WhatIsShivanga';
import TabSection from '../section/TabSection';

/**
 * Renders Render Sections
 *
 * @param {object} sectionContent - Data
 * @param {string} region - Region of Page
 * @param {string} lang - Language of Page
 * @param sectionContent.region
 * @param sectionContent.lang
 * @param sectionContent.pType
 * @param sectionContent.csrData
 * @param sectionContent.pageData
 * @returns {ReactElement} Render Sections
 */

const RenderSections = ({
  section,
  region,
  lang,
  pType,
  csrData,
  pageData,
}) => {
  const { sectionId } = section;
  // console.log('=>', pageData);

  // Render section list home page
  if (sectionId === 'HeaderBanner') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
        lang={lang}
        region={region}
      >
        <HeaderBanner
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
          bgImg={section?.styleConfig?.bgImage}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'vid-text') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        lang={lang}
        region={region}
        background="red"
      >
        <VidText
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'Pricing') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        lang={lang}
        region={region}
      >
        <ShivangaPricing
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'history') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        lang={lang}
        region={region}
      >
        <History
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
          pageData={pageData}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'Section_1') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        lang={lang}
        region={region}
      >
        <WhatIsShivanga
          sectionContent={section.sectionContent}
          language={lang}
          region={region}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'register-sticky-button') {
    return <RegisterStickyButton sectionContent={section.sectionContent} />;
  } else if (sectionId === 'highlights') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        section={section}
        lang={lang}
        region={region}
      >
        <Highlights
          section={section}
          region={region}
          sectionContent={section.sectionContent}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'registration') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        section={section}
        lang={lang}
        region={region}
      >
        <ShivangaPricing
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'sadhana-details') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        section={section}
        lang={lang}
        region={region}
      >
        <TabSection
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'testimonial') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        section={section}
        lang={lang}
        region={region}
      >
        <Testimonials
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'volunteer') {
    return section.sectionContent.length ? (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        lang={lang}
        region={region}
      >
        <Volunteer
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    ) : null;
  } else if (sectionId === 'donate') {
    return section.sectionContent.length ? (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        lang={lang}
        region={region}
      >
        <Donate
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    ) : null;
  } else if (sectionId === 'schedule') {
    return section.sectionContent.length ? (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        lang={lang}
        region={region}
      >
        <Schedule
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    ) : null;
  } else if (sectionId === 'Contact_us') {
    return section.sectionContent.length ? (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        lang={lang}
        region={region}
      >
        <ContactUs
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    ) : null;
  } else if (sectionId === 'attend_in_person') {
    return section.sectionContent.length ? (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        lang={lang}
        region={region}
      >
        <AttendInPerson
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    ) : null;
  } else if (sectionId === 'upcoming-dates') {
    return section.sectionContent.length ? (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        lang={lang}
        region={region}
      >
        <UpcomingShivangaSpurthi
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    ) : null;
  } else if (sectionId === 'FaqSection') {
    return section.sectionContent.length ? (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="30px"
        section={section}
        lang={lang}
        region={region}
      >
        <FaqSection
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    ) : null;
  } else if (sectionId === 'schedule-text') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        lang={lang}
        region={region}
      >
        <ScheduleText
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'Activities') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        lang={lang}
        region={region}
      >
        <Activities
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'Interested') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        lang={lang}
        region={region}
      >
        <Interested
          sectionContent={section.sectionContent}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    );
  } else {
    return (
      <Box as="div" display="block" placeContent="center" w="100%">
        {section?.sectionContent && section?.sectionContent?.length
          ? section?.sectionContent?.map((secCon, i) => {
            return (
              <StyleConfig
                id={section?.sectionId}
                key={section?.sectionId}
                style={section?.styleConfig}
                section={section}
                pB={{ base: '0', md: '15px' }}
                lang={lang}
                region={region}
              >
                <SectionUI
                  key={nanoid(5)}
                  sectionContent={secCon}
                  section={section}
                  region={region}
                  lang={lang}
                  pType={pType}
                  csrData={csrData}
                  pageData={pageData}
                />
              </StyleConfig>
            );
          })
          : null}
      </Box>
    );
  }
};

export default RenderSections;
