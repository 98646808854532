/* Built In Imports */

/* External Imports */
import { Box, Image, useBreakpointValue } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@shi/Utility/StructuredTextParser';
import SimpleButton from '@shi/components/button/SimpleButton';
import VolunteerSt from '@shi/styleData/VolunteerSt';

/* Services */

/**
 * Renders the CardDetail component
 *
 * @param {object} card - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} CardDetail component
 */

const CardDetail = ({ card, region, language }) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      color={'white'}
      height={{ base: '414px', sm: '414px', xl: '441px' }}
      width={{ base: '100%', sm: '327px', xl: '327px' }}
      marginBottom={{ base: '50px', sm: '50px', lg: '0px' }}
      maxW="330px"
    >
      <Box height={{ base: '200px', sm: '200px', xl: '194px' }}>
        <Image
          src={card?.thumbnail?.url}
          title={card?.thumbnail?.title}
          alt={card?.thumbnail?.alt}
          width={'327px'}
        />
      </Box>
      <Box
        flex={1}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <Box
          marginTop={{
            base: '31px',
            sm: '31px',
            xl: '31px',
          }}
          fontSize={{ base: '18px', sm: '18px', xl: '18px' }}
          {...VolunteerSt[language]?.title}
        // marginBottom={'-20px'}
        >
          {card?.title}
        </Box>
        <StructuredTextParser
          sx={{
            p: {
              fontSize: useBreakpointValue({ base: '14px', md: '16px' }),
            },
          }}
          fontFamily="FedraSansStd-book"
          theme="light"
          str={render(card?.description)}
          region={region}
          lang={language}
        />
        <Box
          display={'flex'}
          width="100%"
          gap={{ base: '10px', sm: '10px', xl: '10px' }}
          justifyContent="flex-start"
          alignItems="start"
          marginTop={{ base: '20px', sm: '20px', xl: '0px' }}
        >
          {card?.buttonText && (
            <SimpleButton
              textAlign={'center'}
              style={{
                //   width: '159px',
                buttonStyle: 'Orange',
                buttonText: card?.buttonText,
                linkUrl: card?.buttonLink,
              }}
              region={region}
              fontSize="14px"
              minW={{ base: '145px', sm: '159px' }}
              mb="0"
              lang={language}
              size={{ base: 'xs', sm: 'sm' }}
              maxH="44px"
              padding="10px 29px"
            />
          )}

          {card?.secondCtaLabel && (
            <SimpleButton
              textAlign={'center'}
              fontSize="14px"
              // padding={{ base: '14px 20px !important', sm: '14px 29px' }}
              mb="0"
              minW={{ base: '145px', sm: '156px' }}
              size={{ base: 'xs', sm: 'sm' }}
              style={{
                //   width: '159px',
                border: '1px solid white',
                color: 'white',
                buttonStyle: 'normal',
                buttonText: card?.secondCtaLabel,
                linkUrl: card?.secondCtaLink,
              }}
              region={region}
              lang={language}
              maxH="44px"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CardDetail;
