/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@shi/Utility/StructuredTextParser';
import TopBannerSt from '@shi/styleData/TopBannerSt';

/* Services */

// Utils

/**
 * Renders the ScheduleText on sectionId
 * @param {Object} sectionContent- Data for section
 * @returns {ReactElement} ScheduleText for children component
 */

const ScheduleText = ({ sectionContent, lang, region }) => {
  return (
    <Box px={{ base: '0', md: '23px' }} my="10px">
      {/* Banner Des section  */}
      {sectionContent && (
        <Box background="#FFEBC7" p={{ base: '40px 23px', md: '80px 0 5px 0' }}>
          <Box w={{ base: 'auto', lg: '710px' }} m={{ base: '', lg: '0 auto' }}>
            {sectionContent.map(el => {
              if (el._modelApiKey === 'samskriti_benefit') {
                return (
                  <Box display="flex" alignItems="start">
                    <Box
                      width="30px"
                      display="inline-block"
                      mr="12px"
                      textAlign="center"
                    >
                      <LazyLoadImageComponent
                        src={el?.image?.url}
                        alt={el?.image?.alt || ''}
                        // pt={{ base: '20px', md: '13px' }}
                        width="100%"
                        height="100%"
                        minW={{ base: '20px', md: '30px' }}
                      />
                    </Box>
                    <StructuredTextParser
                      str={render(el?.description)}
                      fontFamily="FedraSansStd-book"
                      className="scheduleText"
                      my="0px"
                      theme="dark"
                      region={region}
                      lang={lang}
                      // className="shivanga-text"
                      color="#000"
                      sx={{
                        p: { fontSize: '16px', mt: '0', color: '#000' },
                        a: {
                          color: '#CF4824 !important',
                          fontsize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: 'normal',
                          textDecorationine: 'underline',
                        },
                      }}
                      {...TopBannerSt[lang].scheduleText}
                    />
                  </Box>
                );
              } else
                return (
                  <Box
                    display="flex"
                    alignItems="start"
                    pl={{ base: '33px', md: '42px' }}
                  >
                    <StructuredTextParser
                      str={render(el?.description || el.body)}
                      fontFamily="FedraSansStd-book"
                      my="0px"
                      theme="light"
                      region={region}
                      lang={lang}
                      // className="shivanga-text"
                      color="#000"
                      // className="scheduleText"
                      {...TopBannerSt[lang].scheduleText}
                      sx={{
                        p: { fontSize: '16px', mt: '0', color: '#000' },
                        a: {
                          color: '#CF4824 !important',
                          fontFamily: 'Fedra Sans Std',
                          fontsize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: 'normal',
                          textDecorationine: 'underline',
                        },
                      }}
                    />
                  </Box>
                );
            })}
          </Box>
          <style>
            {`
              .scheduleText p a, .scheduleText a, scheduleText p strong a, scheduleText p a strong{
                color: #CF4824 !important;
              }
              .scheduleText p{
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
              `}
          </style>
        </Box>
      )}
    </Box>
  );
};

export default ScheduleText;
