import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h1: {
      fontSize: { lg: '44px', md: '40px', base: '28px' },
      fontFamily: 'FedraSansStd-A-medium',
    },
    titleSubtext: {
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-A-medium',
      fontSize: { md: '28px', base: '20px' },
      lineHeight: { base: '28px', md: '30px' },
      color: '#fff',
    },
    bannerDes: {
      fontFamily: {
        base: lang ? config.isoLangFont[lang] : fontFamily,
        md: lang ? config.isoLangFont[lang] : fontFamily,
      },
      fontSize: { base: '16px', md: '22px' },
      color: '#F5E3B5',
    },
    p: {
      textAlign: 'center',
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-book',
      fontSize: { base: '16px', md: '16px' },
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '28px',
    },
    pItalic: {
      textAlign: 'center',
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-book',
      fontSize: '16px',

      fontStyle: 'italic',
      fontWeight: '400',
      lineHeight: '28px',
    },
    quoteText: {
      textAlign: 'center',
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-book',
      fontSize: { base: '16px', md: '18px' },
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '28px',
    },
    scheduleText: {
      color: '#000',
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-book',
      fontSize: '16px',
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const TopBannerSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({
    lang: 'hi',
  }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  cn: commonLangStyle({ lang: 'cn' }),
  ar: commonLangStyle({
    lang: 'ar',
  }),
  id: commonLangStyle({ lang: 'id' }),
};
export default TopBannerSt;
