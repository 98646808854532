/* Built In Imports */

/* External Imports */
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@shi/Utility/StructuredTextParser';
import SectionTitle from '@shi/components/headings/SectionTitle';
import FaqContentsSt from '@shi/styleData/FaqContentsSt';

/* Services */

/* Services */
/**
 * Renders the Faq component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} Faq component
 */
const Faq = ({ sectionContent, region, lang }) => {
  const faqData = sectionContent;
  const title = faqData.find(el => el._modelApiKey === 'section_title');
  return (
    <Box textAlign="center" p={{ base: ' 0 0 40px 0' }}>
      <SectionTitle titleObj={title} maxW="360px" />
      {faqData.map(data => {
        if (data._modelApiKey !== 'faq_section_v2') return null;
        return (
          <Accordion
            allowToggle
            m={{ base: '0 auto', md: '0 auto' }}
            w={{ base: '95%', lg: '870px' }}
          >
            <AccordionItem
              m="15px 0 0 0"
              background="#FFF1D2"
              border="solid 1px #C1A76A"
            >
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      _focus={{ outline: '0' }}
                      p="14px 5%"
                      _hover={{
                        bgColor: 'none',
                      }}
                    >
                      <Box
                        flex="1"
                        textAlign="left"
                        {...FaqContentsSt[lang].stFaText}
                      >
                        {data?.title}
                      </Box>

                      <Box
                        ml="20px"
                        color="#989383"
                        _hover={{ color: '#622210' }}
                        transform={
                          isExpanded
                            ? 'rotate(-180deg)'
                            : 'rotate(0deg)'
                        }
                      >
                        <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.804 2.79686C13.4326 2.39255 12.8408 2.35686 12.4311 2.71501L9.08949 5.63748C8.37901 6.41101 7.22653 6.41101 6.51605 5.63748L3.17449 2.71501C2.76471 2.35686 2.17292 2.39255 1.80158 2.79686C1.38388 3.25101 1.40253 3.9944 1.84227 4.42394L7.11575 9.57224C7.50631 9.95378 8.09923 9.95378 8.49036 9.57224L13.7633 4.42394C14.203 3.9944 14.2217 3.25101 13.804 2.79686Z" fill="#28231E" />
                          <mask id="mask0_481_1778" maskUnits="userSpaceOnUse" x="1" y="2" width="14" height="8">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.804 2.79686C13.4326 2.39255 12.8408 2.35686 12.4311 2.71501L9.08949 5.63748C8.37901 6.41101 7.22653 6.41101 6.51605 5.63748L3.17449 2.71501C2.76471 2.35686 2.17292 2.39255 1.80158 2.79686C1.38388 3.25101 1.40253 3.9944 1.84227 4.42394L7.11575 9.57224C7.50631 9.95378 8.09923 9.95378 8.49036 9.57224L13.7633 4.42394C14.203 3.9944 14.2217 3.25101 13.804 2.79686Z" fill="white" />
                          </mask>
                          <g mask="url(#mask0_481_1778)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24 23.3584L-10 23.3584L-10 -10.6416L24 -10.6416L24 23.3584Z" fill="#28231E" />
                          </g>
                        </svg>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    p="0 5%"
                    pb={4}
                    // borderTop="solid 1px rgba(255, 255, 255, 0.3)"
                    textAlign="left"
                    fontFamily="FedraSansStd-book"
                    fontSize="16px"
                    //  id="one-ap"
                    w={{ base: '100%' }}
                    m="0 auto"
                  >
                    {data?.faqs?.map((datachild, ind) => {
                      return (
                        <Accordion
                          allowToggle
                          m={{ base: '0 auto', md: '0 auto' }}
                          w={{ base: '100%', md: '100%' }}
                        >
                          <AccordionItem
                            m="0 0 0 0"
                            //    id="one1"
                            border="none"
                          >
                            {({ isExpanded }) => (
                              <>
                                <h2>
                                  <AccordionButton
                                    paddingTop="20px"
                                    paddingBottom={{
                                      base:
                                        ind === data?.faqs.length - 1
                                          ? '20px'
                                          : '0',
                                      md: '20px',
                                    }}
                                    paddingLeft={{ md: '16px', base: '0' }}
                                    pr="0"
                                    border="none"
                                    _hover={{
                                      bgColor: 'none',
                                    }}
                                    //   borderRadius="6px"
                                    id="one-btn1"
                                    _focus={{ outline: '0' }}
                                  >
                                    <Box
                                      flex="1"
                                      textAlign="left"
                                      {...FaqContentsSt[lang].stQText}
                                    >
                                      {datachild?.question}
                                    </Box>
                                    {/* <AccordionIcon
                                      marginRight={{
                                        lg: '0',
                                        md: '0px',
                                        base: '0',
                                      }}
                                    /> */}
                                    <Box
                                      ml="20px"
                                      color="#989383"
                                      _hover={{ color: '#622210' }}
                                      transform={
                                        isExpanded
                                          ? 'rotate(-180deg)'
                                          : 'rotate(0deg)'
                                      }
                                    >
                                      <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.804 2.79686C13.4326 2.39255 12.8408 2.35686 12.4311 2.71501L9.08949 5.63748C8.37901 6.41101 7.22653 6.41101 6.51605 5.63748L3.17449 2.71501C2.76471 2.35686 2.17292 2.39255 1.80158 2.79686C1.38388 3.25101 1.40253 3.9944 1.84227 4.42394L7.11575 9.57224C7.50631 9.95378 8.09923 9.95378 8.49036 9.57224L13.7633 4.42394C14.203 3.9944 14.2217 3.25101 13.804 2.79686Z" fill="#28231E" />
                                        <mask id="mask0_481_1778" maskUnits="userSpaceOnUse" x="1" y="2" width="14" height="8">
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.804 2.79686C13.4326 2.39255 12.8408 2.35686 12.4311 2.71501L9.08949 5.63748C8.37901 6.41101 7.22653 6.41101 6.51605 5.63748L3.17449 2.71501C2.76471 2.35686 2.17292 2.39255 1.80158 2.79686C1.38388 3.25101 1.40253 3.9944 1.84227 4.42394L7.11575 9.57224C7.50631 9.95378 8.09923 9.95378 8.49036 9.57224L13.7633 4.42394C14.203 3.9944 14.2217 3.25101 13.804 2.79686Z" fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_481_1778)">
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M24 23.3584L-10 23.3584L-10 -10.6416L24 -10.6416L24 23.3584Z" fill="#28231E" />
                                        </g>
                                      </svg>
                                    </Box>
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel
                                  pb={4}
                                  paddingLeft={{ md: '16px', base: '0' }}
                                  pt="0"
                                  borderTop="none"
                                  // p="5px 20px 20px 5px"
                                  textAlign="left"
                                  id="one-ap1"
                                  borderBottom="solid 1px #A7A7A7"
                                >
                                  <Box>
                                    <StructuredTextParser
                                      // marginLeft={{ md: '20px', base: '0px' }}
                                      p={{ base: '0', md: '0 30px 0 0' }}
                                      my="0"
                                      mt="0"
                                      px="0"
                                      region={region}
                                      lang={lang}
                                      // ml="16px"
                                      {...FaqContentsSt[lang].stFaAns}
                                      str={render(datachild?.answer)}
                                      sx={{ p: { fontSize: '16px' } }}
                                    />
                                    {/* <Text>
                                                        {StructuredTextContent(
                                                          datachild?.answer
                                                        )}
                                                      </Text> */}
                                  </Box>
                                </AccordionPanel>
                              </>
                            )}
                          </AccordionItem>
                        </Accordion>
                      );
                    })}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        );
      })}
      <style jsx global>{`
      .chakra-accordion__item button svg {
        color:#28231E,
        height:34px,
        width:34px
      }
    `}</style>
    </Box>


  );
};

export default Faq;
