/* Built In Imports */
import Head from 'next/head';
import { useRouter } from 'next/router';

/* External Imports */

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */

const HeadComponent = ({ data, availableLocals, script }) => {
  const router = useRouter();

  const { lang } = router.query;
  const seoFieldsHandler = () => {
    if (data && data.length > 0) {
      return data.map(d => {
        const newTAG = [];
        let name = '';
        let content = '';
        let property = '';
        let href = '';
        let rel = '';
        if (d.tag === 'meta') {
          Object.entries(d.attributes).map(([k, v]) => {
            name = k.trim() === 'name' ? v : name;
            content = k.trim() === 'content' ? v : content;
            property = k.trim() === 'property' ? v : property;
            if (content !== '' && name !== '') {
              newTAG.push(<meta name={name} content={content} />);
            } else if (content !== '' && property !== '') {
              newTAG.push(
                <meta key={v} content={content} property={property} />
              );
            }
          });
        }
        if (d.tag === 'link' && d.attributes !== null) {
          Object.entries(d.attributes).map(([key, value]) => {
            href = key.trim() === 'href' ? value : href;
            rel = key.trim() === 'rel' ? value : rel;
            if (href !== '' && rel != '') {
              newTAG.push(<link key={value} href={href} rel={rel} />);
            }
          });
        }
        if (d.tag === 'title') {
          newTAG.push(<title>{d.content}</title>);
        }

        return newTAG;
      });
    }
  };

  const availableLocalsHandler = () => {
    // const router = useRouter();
    if (availableLocals && availableLocals.length) {
      return availableLocals.map((l, v) => {
        const separateLocal = l.locale?.split('_');
        const availLocals = separateLocal[1]
          ? `${separateLocal[0].toLowerCase()}-${separateLocal[1].toLowerCase()}`
          : `${separateLocal[0].toLowerCase()}`;
        const url = `${config.BASE_PATH}${l.value}`;
        return (
          <link key={v} rel="alternate" hrefLang={availLocals} href={url} />
        );
      });
    }
  };

  const setScript = () => {
    if (script) {
      return (
        <script
          dangerouslySetInnerHTML={{
            __html: script,
          }}
        ></script>
      );
    }
  };
  const setCSSLink = () => {
    setTimeout(function () {
      if (typeof document !== 'undefined') {
        let fileRef = document.createElement(`link`);
        fileRef.rel = `stylesheet`;
        fileRef.type = `text/css`;
        fileRef.href = `${config.staticPath}/assets/fonts/mukta-fonts.css?family=FedraSerifAStdBook`;
        document.getElementsByTagName(`head`)[0].appendChild(fileRef);
      }
    }, 1800);
    return null;
  };

  return (
    <Head>
      {setScript()}
      {availableLocalsHandler()}
      {seoFieldsHandler()}
      <link
        rel="icon"
        href={`${config.imgPath}/d/46272/1720450736-favicon.ico`}
      />
      <link
        rel="apple-touch-icon"
        href={`${config.imgPath}/d/46272/1690792477-isha_logo_black.svg`}
      />
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=yes"
      />
      <meta name="theme-color" content="#e4ded4" />
      <meta name="MobileOptimized" content="width" />
      <meta name="HandheldFriendly" content="true" />
      {setCSSLink()}
      {lang === 'hi' && (
        <link
          rel="stylesheet"
          href={`${config.staticPath}/assets/fonts/mukta-fonts.css?family=Mukta`}
        />
      )}
      {lang === 'ta' && (
        <link
          rel="stylesheet"
          href={`${config.staticPath}/assets/fonts/mukta-fonts.css?family=MuktaMalar`}
        />
      )}
      {lang === 'ta' && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Anek+Tamil:wght@400;500;600&display=swap"
        />
      )}
      {lang === 'te' && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@400;500;600&display=swap"
        />
      )}
      {lang === 'ml' && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@400;500;600&display=swap"
        />
      )}
      {lang === 'bn' && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Anek+Bangla:wght@400;500;600&display=swap"
        />
      )}
      {lang === 'gu' && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Anek+Gujarati:wght@400;500;600&display=swap"
        />
      )}
      {lang === 'kn' && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Anek+Kannada:wght@400;500;600&display=swap"
        />
      )}
      {lang === 'hi' && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Anek+Devanagari:wght@400;500;600&display=swap"
        />
      )}
      {lang === 'zh-tw' ||
        (lang === 'zh' && (
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;600&display=swap"
          />
        ))}
      {lang === 'ar' && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Almarai:wght@400;700&display=swap"
        />
      )}
    </Head>
  );
};

export default HeadComponent;
