/* Built In Imports */

/* External Imports */
import { Box, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import TopBannerSt from '@shi/styleData/TopBannerSt';

/* Services */

// Utils

/**
 * Renders the Quote on sectionId
 * @param {Object} sectionContent- Data for section

 * @returns {ReactElement} Designs style Quote for children component
 */

const Quote = ({ sectionContent, lang, region }) => {
  return (
    <Box>
      {sectionContent && (
        <Box
          p={{ lg: '20px 15px', base: '20px 15px 40px 15px' }}
          minH={{ base: '240px' }}
        >
          <Box
            maxW={{ base: '302px', md: '460px' }}
            margin="0 auto"
            textAlign="center"
            display="flex"
            alignItems="center"
            flexDir="column"
            justifyContent="center"
          >
            <Text
              color="#C1C1C1"
              fontSize={{ base: '16px', md: '18px' }}
              textAlign={{ base: 'center', sm: 'center' }}
              margin="20px 0 20px 0"
              lineHeight="28px"
              maxW={460}
              width="100%"
              fontFamily="FedraSansStd-book"
              minH={{ base: '70px', md: '70px' }}
              {...TopBannerSt[lang].quoteText}
            >
              {sectionContent.quote}
            </Text>
            <LazyLoadImageComponent
              src={`${config.imgPath}/d/46272/1700844150-shivanga_sadhguru_signature.png`}
              //   title={sectionContent?.[6]?.image?.title}
              alt={'Sadhguru Signature'}
              height={{ base: '40px', md: '60px' }}
              width={{ base: '86px', md: '128px' }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Quote;
