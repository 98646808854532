/* Built In Imports */

/* External Imports */
import { Box, Center, Flex, Icon, Image } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@shi/Utility/StructuredTextParser';
import CardProgram2ColumnGroup from '@shi/components/card/CardProgramColumnGroup2';
import SectionTitle from '@shi/components/headings/SectionTitle';
import VolunteerSt from '@shi/styleData/VolunteerSt';

/* Services */

/**
 * Renders the ShivangaVolunteer component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} ShivangaVolunteer component
 */

const ShivangaVolunteer = ({ sectionContent, region, lang }) => {
  return (
    <Box
      paddingTop={{
        base: '30px',
        sm: '30px',
        md: '30px',
        lg: '30px',
      }}
      paddingBottom={{
        base: '0px',
        sm: '0px',
        md: '55px',
        lg: '115px',
      }}
    >
      <Center>
        <SectionTitle
          titleObj={sectionContent[0]}
          mx="auto"
          titleDecoration={sectionContent[0]?.titleDecoration}
          {...VolunteerSt[lang].h1}
        />
      </Center>
      <Box
        display={'flex'}
        flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
        justifyContent={'center'}
        alignItems={'center'}
        gap={'50px'}
        mt={{ base: '21px', sm: '21px', md: '0px' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          width={{ base: '321px', sm: '321px', md: '594px' }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Flex px={{ base: '16px', md: '0' }}>
            <Icon
              mt="20px"
              width="14px"
              height="14px"
              viewBox="0 0 14px 14px"
              fill="none"
              mr="10px"
            >
              <g clip-path="url(#clip0_1267_634)">
                <path
                  d="M2.48711 1.81916C3.047 1.09136 3.72867 0.475053 4.49918 0H4.53942L5.6682 1.07724V1.57605C5.12088 2.13188 4.67502 2.78661 4.3523 3.50838C3.94989 4.3467 3.76075 5.38413 3.76075 6.65209C3.75306 7.0438 3.79844 7.43467 3.89556 7.81317C3.98077 8.10543 4.09754 8.38667 4.24365 8.6515C4.40757 8.9242 4.58767 9.18598 4.78288 9.43533C5.04255 9.76436 5.26783 10.1213 5.45492 10.5C5.61121 10.8743 5.68412 11.2805 5.6682 11.6883C5.6783 12.1108 5.57593 12.5279 5.37242 12.8934C5.1735 13.2348 4.89083 13.5147 4.55351 13.7045C4.19842 13.9023 3.80126 14.004 3.39858 14H3.38449C2.74113 13.9948 2.12272 13.74 1.65009 13.2853C1.11085 12.7642 0.697229 12.118 0.442844 11.3991C0.146925 10.6061 -0.0032343 9.76218 0.000188502 8.91138C-0.00781249 7.5584 0.238962 6.21693 0.726547 4.96287C1.1443 3.82168 1.73906 2.75966 2.48711 1.81916ZM10.803 1.81916C11.3646 1.08588 12.0532 0.468831 12.8332 0H12.8593L14.0022 1.07724V1.57605C13.4459 2.1278 12.9959 2.78507 12.6762 3.51257C12.2738 4.35089 12.0726 5.38832 12.0726 6.65628C12.0686 7.0486 12.118 7.43953 12.2195 7.81736C12.2992 8.11202 12.417 8.39403 12.5696 8.65569C12.7044 8.87994 12.8774 9.1461 13.0927 9.43952C13.3531 9.76803 13.5784 10.125 13.7648 10.5042C13.9279 10.8767 14.0064 11.2833 13.9942 11.6925C14.0002 12.1139 13.8959 12.5291 13.6923 12.8934C13.4978 13.2369 13.2157 13.5176 12.8774 13.7045C12.5223 13.9041 12.124 14.0058 11.7205 14H11.7084C11.0687 13.9977 10.4537 13.7423 9.98811 13.2853C9.43932 12.7694 9.01943 12.122 8.76477 11.3991C8.47137 10.6056 8.32394 9.76167 8.33017 8.91138C8.31644 7.55926 8.55847 6.21757 9.04244 4.96287C9.47202 3.82762 10.0657 2.76746 10.803 1.81916Z"
                  fill="#FFEBC7"
                />
              </g>
              <defs>
                <clipPath id="clip0_1267_634">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </Icon>
            <Box maxW="545px">
              <StructuredTextParser
                width="100%"
                theme="light"
                str={render(sectionContent[1].body)}
                sx={{ p: { ...VolunteerSt[lang].p } }}
                region={region}
                lang={lang}
              />
            </Box>
            <Icon
              alignSelf="flex-end"
              width="14px"
              height="14px"
              viewBox="0 0 14px 14px"
              fill="none"
              ml="10px"
            >
              <path
                d="M11.5168 12.1808C10.9569 12.9086 10.2752 13.5249 9.50472 14L9.46448 14L8.33571 12.9228L8.33571 12.424C8.88302 11.8681 9.32889 11.2134 9.65161 10.4916C10.054 9.6533 10.2432 8.61587 10.2432 7.34791C10.2508 6.9562 10.2055 6.56533 10.1083 6.18683C10.0231 5.89457 9.90636 5.61333 9.76026 5.3485C9.59633 5.0758 9.41624 4.81402 9.22102 4.56467C8.96136 4.23564 8.73607 3.87874 8.54899 3.50001C8.3927 3.12574 8.31978 2.71948 8.33571 2.31168C8.3256 1.88924 8.42798 1.47214 8.63148 1.10659C8.83041 0.765231 9.11308 0.485263 9.4504 0.295513C9.80548 0.0976605 10.2026 -0.00396216 10.6053 3.17805e-06L10.6194 3.17674e-06C11.2628 0.00515105 11.8812 0.259971 12.3538 0.714674C12.8931 1.23576 13.3067 1.88201 13.5611 2.6009C13.857 3.39392 14.0071 4.23782 14.0037 5.08862C14.0117 6.4416 13.7649 7.78307 13.2774 9.03713C12.8596 10.1783 12.2648 11.2403 11.5168 12.1808ZM3.2009 12.1808C2.63933 12.9141 1.9507 13.5312 1.17072 14L1.14456 14L0.0017089 12.9228L0.00170886 12.424C0.557958 11.8722 1.00799 11.2149 1.32766 10.4874C1.73008 9.64911 1.93128 8.61168 1.93128 7.34372C1.93532 6.9514 1.88586 6.56047 1.7844 6.18264C1.70466 5.88798 1.58688 5.60597 1.4343 5.34431C1.29949 5.12006 1.12646 4.8539 0.911164 4.56049C0.650821 4.23197 0.425489 3.87499 0.239131 3.49581C0.0760184 3.12333 -0.00247189 2.7167 0.00975513 2.30749C0.00366779 1.88605 0.108013 1.47086 0.311565 1.10659C0.506125 0.763076 0.788167 0.482355 1.12646 0.295513C1.48163 0.0959418 1.87988 -0.00578004 2.2834 3.95434e-06L2.29547 3.95321e-06C2.93519 0.00225361 3.55017 0.257733 4.01579 0.714675C4.56459 1.2306 4.98447 1.878 5.23913 2.6009C5.53253 3.39441 5.67997 4.23833 5.67374 5.08863C5.68747 6.44074 5.44544 7.78243 4.96146 9.03713C4.53189 10.1724 3.93817 11.2325 3.2009 12.1808Z"
                fill="#FFEBC7"
              />
            </Icon>
          </Flex>
          <Box marginTop={{ base: '18px', sm: '18px', md: '34px' }}>
            <Image src={sectionContent[2]?.image?.url} />
          </Box>
        </Box>
      </Box>
      <CardProgram2ColumnGroup
        region={region}
        language={lang}
        sectionContent={sectionContent[3]}
      />
    </Box>
  );
};

export default ShivangaVolunteer;
