/* Built In Imports */

/* External Imports */
import { Box, Image, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import config from '@config';
import SectionTitle from '@shi/components/headings/SectionTitle';
import CompWithLeastStyle from '@shi/styleData/CompWithLeastStyle';

/* Services */

/**
 * Renders the Highlights component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} Highlights component
 */

const Highlights = ({ sectionContent, region, lang }) => {
  const benefitsData = sectionContent[0];
  return (
    <Box
      backgroundSize={{ base: 'contain !important', md: 'cover !important' }}
      background={{
        base: `url('${config.staticPath}/d/46272/1701252094-shivanga_why_bg_mobile.jpg') no-repeat #101010`,
        md: `url('${config.staticPath}/d/46272/1701175411-background_7hill.jpg') no-repeat #101010`,
      }}
    >
      <Box>
        <Box
          w={{ base: '85%', sm: '50%', xl: '85%' }}
          m={{ base: '0 auto', md: '0 auto' }}
          p={{ base: '55px 0' }}
          textAlign={{ base: 'center' }}
        >
          <SectionTitle titleObj={benefitsData.title[0]} />
          <Box
            w={{ base: '85%', sm: '50%', xl: '85%' }}
            m={{ base: '0 auto', md: '0 auto' }}
            textAlign={{ base: 'center' }}
            color="white"
          >
            {benefitsData.title[0]?.titleSubtext}
          </Box>
          <Box
            display={{ base: 'flex' }}
            flexDirection={{ base: 'column', sm: 'row' }}
            justifyContent="center"
            flexWrap={{ base: 'unset', sm: 'wrap', xl: 'unset' }}
            gridGap="15px"
            m="40px auto"
          >
            {benefitsData &&
              benefitsData?.benefits.map(data => {
                return (
                  <Box
                    w={274}
                    mx={{ base: 'auto', md: '0' }}
                    // p="0 10px"
                    flexDir="column"
                    alignItems="start"
                    key={nanoid}
                  >
                    <Image
                      loading="lazy"
                      alt={data?.image?.alt || data?.image?.title || ''}
                      // title={data?.image?.title}
                      h="162px"
                      w={benefitsData?.style === 'default' ? '162px' : '274px'}
                      objectFit="cover"
                      bg="lightgray -6px 0px / 102.92% 89.51% no-repeat"
                      src={data?.image?.url}
                      m={{ base: '0 auto 22px auto' }}
                      borderRadius={
                        benefitsData?.style === 'default' ? '50%' : ''
                      }
                    />
                    <Text
                      as="h6"
                      color="#fff"
                      fontFamily="FedraSansStd-book"
                      fontSize={{ base: '16px', md: '18px' }}
                      lineHeight={{ base: '24px', md: '26px' }}
                      textAlign="center"
                      {...CompWithLeastStyle[lang].highLightContent}
                      m={{ base: '0 0 27px', lg: '0 auto 15px' }}
                      // w={{ base: '80%', xl: 'auto' }}
                      px="2px"
                    >
                      {data?.text}
                    </Text>
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Highlights;
