/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import SimpleButton from '@shi/components/button/SimpleButton';

/* Services */
import { isVisInViewport } from '@components/Utility/Shared/SharedService';

/**
 * Renders RegisterStickyButton
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @returns {ReactElement} RegisterStickyButton
 */
const RegisterStickyButton = ({ sectionContent, region, lang }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // if (!isMobile) {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
    // }
  }, []);

  const listenToScroll = () => {
    const footerDiv = document.getElementById('footer');
    const pricingDiv = document.getElementById('Pricing');
    const heightToHideFrom = window.innerHeight * 1.3;
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;
    // console.log('winScroll', winScroll, heightToHideFrom)

    if (winScroll > heightToHideFrom) {
      const footerInViewPort = footerDiv && isVisInViewport(footerDiv);
      const pricingInViewPort = pricingDiv && isVisInViewport(pricingDiv);
      if (footerInViewPort || pricingInViewPort) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }
    else {
      setIsVisible(false);
    }
  };

  return (
    isVisible && (
      <Box
        position="fixed"
        sx={{
          filter:
            'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.10)) ' +
            'drop-shadow(0px 15px 15px rgba(0, 0, 0, 0.09)) ' +
            'drop-shadow(0px 34px 21px rgba(0, 0, 0, 0.05)) ' +
            'drop-shadow(0px 61px 24px rgba(0, 0, 0, 0.01)) ' +
            'drop-shadow(0px 96px 27px rgba(0, 0, 0, 0.00))',
          color: '#FFFFFF',
        }}
        background={{ base: '#171001', md: 'none' }}
        top={{ base: 'unset', md: '50%' }}
        py="15px"
        bottom={{ base: '0', md: 'unset' }}
        zIndex="9"
        right={{ base: 'unset', md: '0', xl: '0' }}
        left={{ base: '50%', md: 'unset' }}
        width={{ base: '100%', md: 'auto' }}
        transform={{ base: 'translateX(-50%)', md: 'none' }}
        color="#fff"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <SimpleButton
          style={sectionContent[0]}
          target="_blank"
          background="#CA4E2A"
          borderRadius={3}
          fontFamily="FedraSansStd-medium"
          textDecor="none"
          mx={{ base: '0', md: '0' }}
          p="18px 20px"
          fontSize="16px"
          maxW={{ base: '217px', md: '148px' }}
          minW={{ base: '217px', md: '148px' }}
          mb="0"
          mt="0"
          region={region}
          lang={lang}
          _focus={{ outline: '0' }}
          _hover={{ background: '#983C21', textDecoration: 'none' }}
        />
      </Box>
    )
  );
};

export default RegisterStickyButton;
