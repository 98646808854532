/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect } from 'react';

/* External Imports */
import { Box, Center, Flex, Heading, List } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-scroll';
import StickyBox from 'react-sticky-box';

/* Internal Imports */
/* Components */
import useDynamicRefs from '@hooks/useDynamicRefs';
import StructuredTextParser from '@shi/Utility/StructuredTextParser';
import SingleLineText from '@shi/components/Text/SingleLineText';
import SingleImage from '@shi/components/card/SingleImage';
import SectionTitle from '@shi/components/headings/SectionTitle';
import SadhanaContentSt from '@shi/styleData/SadhanaContentSt';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const TabSection = ({ sectionContent, region, lang }) => {
    const router = useRouter();
    const routerHashPath = router?.asPath.split('#')[1];
    const [getRef, setRef] = useDynamicRefs();
    const isMobile = useMediaQuery({ maxWidth: 1025 });
    let currentSelectedMenuId = '';

    const scrollToCategory = id => {
        isMobile && getRef(id)?.current?.scrollIntoView({ inline: 'start' });
    };

    useEffect(() => {
        if (routerHashPath) {
            // if (isMobile) {
            //     setTimeout(() => {
            //         while (router?.asPath.split('#')[1] !== currentSelectedMenuId) {
            //             scrollToCategory(routerHashPath);
            //             console.log(
            //                 'useMediaQuery',
            //                 router?.asPath.split('#')[1],
            //                 currentSelectedMenuId
            //             );
            //             currentSelectedMenuId = router?.asPath.split('#')[1];
            //         }
            //     }, 4000);
            // }

            setTimeout(() => {
                const element = document.getElementById(routerHashPath);
                const offset = 0;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            }, 5000);
        }
    }, [router.asPath]);
    // console.log(sectionContent, "tabsection");
    const Tab1_content = sectionContent.find(el => el.fieldId === 'Tab1_content');
    const Tab2_content = sectionContent.find(el => el.fieldId === 'Tab2_content');
    const Tab3_content = sectionContent.find(el => el.fieldId === 'Tab3_content');
    const Tab1_content1 = sectionContent.find(
        el => el.fieldId === 'Tab1_content1'
    );
    const Tab2_content1 = sectionContent.find(
        el => el.fieldId === 'Tab2_content1'
    );
    const Tab3_content1 = sectionContent.find(
        el => el.fieldId === 'Tab3_content1'
    );

    return (
        <Box>
            <Center flexDirection="column">
                <SectionTitle titleObj={sectionContent[0]} />
            </Center>
            <SingleLineText
                sectionContent={sectionContent[1]}
                lang={lang}
                region={region}
                mx="auto"
                textAlign="center"
                my={{ base: '20px', md: '35px' }}
                color="#000"
                {...SadhanaContentSt[lang].eligibilityText}
            />
            <Box
                maxW={870}
                w="100%"
                mx="auto"
                display="flex"
                flexDir="column"
                height="100%"
            >
                <StickyBox style={{ zIndex: 8 }}>
                    <List
                        w="100%"
                        ml="auto"
                        textAlign={['start', 'start', 'end', 'end']}
                        mt={0}
                        display="flex"
                        flexDirection="row"
                        // overflowY={{
                        //     base: 'hidden',
                        //     md: 'inherit',
                        // }}
                        // overflowX={{ sm: 'scroll', lg: 'visible' }}
                        whiteSpace={{ base: 'nowrap', lg: 'normal' }}
                        listStyleType="none"
                        pl={['20px', '20px', '20px', 0]}
                        // flexWrap={['nowrap', 'nowrap', 'nowrap', 'wrap']}
                        height={['55px', '55px', '55px', '100%']}
                        justifyItems={['center', 'center', 'center', 'flex-start']}
                        alignItems={['center', 'center', 'flex-start', 'flex-start']}
                        bgColor={'#FAE2AB'}
                        borderBottom={{ base: 'solid 1px #dedede', md: 'none' }}
                        justifyContent="space-between"
                    >
                        {sectionContent[2].data.map((item, i) => {
                            let to = item.Tab_title.replace(/ /g, '-')
                                .toLowerCase()
                                .toString();
                            return (
                                <Box key={i} ref={setRef(to)} as="li" flex={1}>
                                    <Link
                                        activeClass="activeNav"
                                        spy
                                        hashSpy
                                        smooth
                                        duration={40}
                                        offset={isMobile ? -50 : -30}
                                        to={to}
                                        // saveHashHistory={false}
                                        onSetActive={() => scrollToCategory(to)}
                                        href={`#${to}`}
                                        style={{ width: '100%' }}
                                    >
                                        <Box
                                            p={{ base: '15px 10px', lg: '15px 20px 15px 10px' }}
                                            fontSize={{ base: '14px', md: '18px' }}
                                            display="block"
                                            textTransform="capitalize"
                                            color={'#595959'}
                                            textAlign="center"
                                            borderBottom="4px solid transparent"
                                            // ml="-9px"
                                            {...SadhanaContentSt[lang].tabText}
                                        >
                                            {item.Tab_title}
                                        </Box>
                                    </Link>
                                </Box>
                            );
                        })}
                    </List>
                </StickyBox>
                <Flex w="100%" bg="#FFF7E5" justifyItems="flex-end" height="100%">
                    <Box width="100%" px={{ base: '30px', md: '40px' }}>
                        <Box
                            id={sectionContent[2].data[0].Tab_title.replace(/ /g, '-')
                                .toLowerCase()
                                .toString()}
                            ref={setRef(
                                `${sectionContent[2].data[0].Tab_title.replace(
                                    / /g,
                                    '-'
                                ).toLowerCase()}`
                            )}
                            py={{ base: '40px', md: '40px' }}
                            borderBottom="1px solid #979797"
                            mb={{
                                base: '30px',
                                md: '0',
                            }}
                        >
                            <Flex flexWrap="wrap" alignItems="flex-end">
                                <Box
                                    maxW="420px"
                                    mr={{ base: '0', md: '30px' }}
                                    mb={{ base: '30px', md: '0' }}
                                    w="100%"
                                >
                                    <SingleImage
                                        px="0"
                                        w="100%"
                                        my="0"
                                        sectionContent={sectionContent[3]}
                                    />
                                </Box>
                                <Flex
                                    flexDir="column"
                                    w="100%"
                                    maxW={{ base: '100%', md: 328 }}
                                >
                                    <Heading as="h3" {...SadhanaContentSt[lang].h4}>
                                        {sectionContent[4]?.titleText}
                                    </Heading>
                                    <Heading as="h3" {...SadhanaContentSt[lang].h4}>
                                        {sectionContent[4]?.titleSubtext}
                                    </Heading>
                                    <Box
                                        height={0}
                                        w="100%"
                                        maxW={{ base: '100%', md: 328 }}
                                        mt={{ base: '16px', md: '30px' }}
                                        borderBottom="1px solid #E4A100"
                                    ></Box>
                                </Flex>
                            </Flex>
                            <StructuredTextParser
                                str={render(Tab1_content.body)}
                                region={region}
                                lang={lang}
                                {...SadhanaContentSt[lang].cardInfo2Text}
                                sx={{
                                    a: { textDecoration: 'underline' },
                                }}
                            />
                            <StructuredTextParser
                                str={render(Tab1_content1.body)}
                                region={region}
                                lang={lang}
                                {...SadhanaContentSt[lang].cardInfo2Text}
                                sx={{
                                    a: { textDecoration: 'underline' },
                                }}
                            />
                        </Box>
                        <Box
                            id={sectionContent[2].data[1].Tab_title.replace(/ /g, '-')
                                .toLowerCase()
                                .toString()}
                            ref={setRef(
                                `${sectionContent[2].data[1].Tab_title.replace(
                                    / /g,
                                    '-'
                                ).toLowerCase()}`
                            )}
                            py={{ base: '40px', md: '40px' }}
                            borderBottom="1px solid #979797"
                            mb={{
                                base: '30px',
                                md: '0',
                            }}
                        >
                            <Flex flexWrap="wrap" alignItems="flex-end">
                                <Box
                                    maxW="420px"
                                    mr={{ base: '0', md: '30px' }}
                                    mb={{ base: '30px', md: '0' }}
                                    w="100%"
                                >
                                    <SingleImage
                                        px="0"
                                        w="100%"
                                        my="0"
                                        sectionContent={sectionContent[6]}
                                    />
                                </Box>
                                <Flex
                                    flexDir="column"
                                    w="100%"
                                    maxW={{ base: '100%', md: 328 }}
                                >
                                    <Heading as="h3" {...SadhanaContentSt[lang].h4}>
                                        {sectionContent[7].titleText}
                                    </Heading>
                                    <Heading as="h3" {...SadhanaContentSt[lang].h4}>
                                        {sectionContent[7].titleSubtext}
                                    </Heading>
                                    <Box
                                        height={0}
                                        w="100%"
                                        maxW={{ base: '100%', md: 328 }}
                                        mt={{ base: '16px', md: '30px' }}
                                        borderBottom="1px solid #E4A100"
                                    ></Box>
                                </Flex>
                            </Flex>
                            <StructuredTextParser
                                str={render(Tab2_content.body)}
                                region={region}
                                lang={lang}
                                {...SadhanaContentSt[lang].cardInfo2Text}
                                sx={{
                                    a: { textDecoration: 'underline' },
                                }}
                            />
                            <StructuredTextParser
                                str={render(Tab2_content1.body)}
                                region={region}
                                lang={lang}
                                {...SadhanaContentSt[lang].cardInfo2Text}
                                sx={{
                                    a: { textDecoration: 'underline' },
                                }}
                            />
                        </Box>
                        <Box
                            id={sectionContent[2].data[2].Tab_title.replace(/ /g, '-')
                                .toLowerCase()
                                .toString()}
                            ref={setRef(
                                `${sectionContent[2].data[2].Tab_title.replace(
                                    / /g,
                                    '-'
                                ).toLowerCase()}`
                            )}
                            py={{ base: '40px', md: '40px' }}
                            mb={{
                                base: '30px',
                                md: '0',
                            }}
                        >
                            <Flex flexWrap="wrap" alignItems="flex-end">
                                <Box
                                    maxW="420px"
                                    mr={{ base: '0', md: '30px' }}
                                    mb={{ base: '30px', md: '0' }}
                                    w="100%"
                                >
                                    <SingleImage
                                        px="0"
                                        w="100%"
                                        my="0"
                                        sectionContent={sectionContent[9]}
                                    />
                                </Box>
                                <Flex
                                    flexDir="column"
                                    w="100%"
                                    maxW={{ base: '100%', md: 328 }}
                                >
                                    <Heading as="h3" {...SadhanaContentSt[lang].h4}>
                                        {sectionContent[10].titleText}
                                    </Heading>
                                    <Heading as="h3" {...SadhanaContentSt[lang].h4}>
                                        {sectionContent[10].titleSubtext}
                                    </Heading>
                                    <Box
                                        height={0}
                                        w="100%"
                                        maxW={{ base: '100%', md: 328 }}
                                        mt={{ base: '16px', md: '30px' }}
                                        borderBottom="1px solid #E4A100"
                                    ></Box>
                                </Flex>
                            </Flex>
                            <StructuredTextParser
                                region={region}
                                str={render(Tab3_content.body)}
                                lang={lang}
                                {...SadhanaContentSt[lang].cardInfo2Text}
                                sx={{
                                    a: { textDecoration: 'underline' },
                                }}
                            />
                            <StructuredTextParser
                                region={region}
                                str={render(Tab3_content1.body)}
                                lang={lang}
                                {...SadhanaContentSt[lang].cardInfo2Text}
                                sx={{
                                    a: { textDecoration: 'underline' },
                                }}
                            />
                        </Box>
                    </Box>
                </Flex>
                <style global jsx>
                    {`
            .activeNav {
              background: transparent;
              display: inline-block;
              margin-left: 0px;
            }
            .activeNav div {
              border-bottom: 4px solid #ce8200;
              color: #222222;
              font-weight: 500;
              font-family: FedraSansStd-medium;
            }
            @media (min-width: 992px) {
              .activeNav {
                color: #222;
              }
              .activeNav div {
                border-bottom: 4px solid #ce8200;
                color: #222;
                font-weight: 500;
              }
            }
            @media screen and (max-width: 768px) {
              .activeNav {
                background: transparent;
                border-left: 0;
                color: #28231e;
                display: inline-block;
              }
              .activeNav div {
                border-bottom: 4px solid #ce8200;
                color: #222;
                font-weight: 500;
              }
            }
            @media screen and (max-width: 480px) {
              .activeNav {
                background: transparent;
                border-left: 0;
                color: #222;
                font-weight: 500;
                display: block;
              }
              .activeNav div {
                border-bottom: 4px solid #ce8200;
                color: #222;
                font-weight: 500;
              }
            }
          `}
                </style>
            </Box>
        </Box>
    );
};

export default TabSection;
