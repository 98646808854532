/* Built In Imports */

/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@shi/Utility/StructuredTextParser';
import MidContainer from '@shi/components/Layout/MidContainer';
import SingleLineText from '@shi/components/Text/SingleLineText';
import CardInfo2ColumnGroup from '@shi/components/card/CardInfo2ColumnGroup';
import SingleImage from '@shi/components/card/SingleImage';
import SectionTitle from '@shi/components/headings/SectionTitle';
import MediaEmbedV2 from '@shi/components/media/MediaEmbedV2';
import Quote from '@shi/components/quote/Quote';
import Gallery from '@shi/components/slider/Gallery';
import BenefitsGroup from '@shi/section/BenefitsGroup';
import CardProgram3ColumnGroup from '@shi/section/CardProgram3ColumnGroup';

/* Services */

// Utils

/**
 * Renders the SectionUI on sectionId
 * @param {Object} sectionContent- Data for section
 * @param {Object} section
 * @returns {ReactElement} Designs SectionUI for children component
 */

const SectionUI = ({
  sectionContent,
  section,
  region,
  lang,
  pType,
  csrData,
  pageData,
}) => {
  return (
    <Flex w="100%" flexDirection="column">
      {RenderComponents({
        sectionContent,
        region,
        lang,
        section,
        pType,
        pageData,
        csrData,
      })}
    </Flex>
  );
};
const RenderComponents = ({
  sectionContent,
  region,
  lang,
  section,
  pType,
  pageData,
  csrData,
}) => {
  switch (sectionContent._modelApiKey) {
    case 'section_title':
      return <SectionTitle titleObj={sectionContent} />;
    case 'media_embed_v2':
      return (
        <Box
          width={{ base: '100%', lg: '755px' }}
          mx="auto"
          px={{ base: '16px', md: '0' }}
          mb={{ base: '20px', md: '40px' }}
        >
          <MediaEmbedV2
            sectionContent={sectionContent}
            lang={lang}
            region={region}
          />
        </Box>
      );

    case 'benefits_group':
      return (
        <BenefitsGroup
          region={region}
          lang={lang}
          sectionContent={sectionContent}
        />
      );
    case 'structured_text':
      return (
        <RenderStructuredText
          sectionContent={sectionContent}
          pageData={pageData}
          region={region}
          lang={lang}
        />
      );
    case 'single_image':
      return (
        <Box p="0 25px" textAlign="center">
          <SingleImage
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            sectionContent={sectionContent}
            region={region}
            lang={lang}
            display="flex"
            mx="auto"
          />
        </Box>
      );
    case 'single_line_text':
      return (
        <SingleLineText
          sectionContent={sectionContent}
          lang={lang}
          region={region}
        />
      );
    // case 'samskriti_benefit':
    //   return (
    //     <Box display="flex" alignItems="center" pt="10px">
    //       <SamsKritiBenefit
    //         sectionContent={sectionContent}
    //         lang={lang}
    //         region={region}
    //       />
    //     </Box>
    //   );
    case 'quote':
      return <Quote sectionContent={sectionContent} lang={lang} />;
    case 'card_program3_column_group':
      return (
        <CardProgram3ColumnGroup
          sectionContent={sectionContent}
          region={region}
          lang={lang}
        />
      );
    case 'card_info2_column_group':
      return (
        <CardInfo2ColumnGroup
          sectionContent={sectionContent}
          lang={lang}
          region={region}
        />
      );
    case 'gallery':
      return (
        <Gallery sectionContent={sectionContent} lang={lang} region={region} />
      );
  }
};
export default SectionUI;

export const RenderStructuredText = ({
  sectionContent,
  region,
  lang,
  maxWidth,
}) => {
  return (
    <MidContainer w="620px" pt="15px">
      <Box
        sx={{
          h2: {
            fontSize: '26px',
          },
          h3: {
            fontSize: '18px',
          },
        }}
        px={{ base: '15px', md: '0' }}
        py={{ base: '0', md: '0' }}
        textAlign={
          sectionContent?.fieldId === 'center-align' ? 'center' : 'initial'
        }
      >
        <StructuredTextParser
          str={render(sectionContent.body)}
          region={region}
          lang={lang}
          textAlign={sectionContent.style}
          theme={
            sectionContent.style !== 'shivanga-white-txt' ? 'dark' : 'light'
          }
          // fontSize="18px"
          maxWidth={'620px'}
        />
      </Box>
    </MidContainer>
  );
};
