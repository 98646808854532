/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@shi/Utility/StructuredTextParser';
import SectionTitle from '@shi/components/headings/SectionTitle';
import AttendInPersonSt from '@shi/styleData/AttendInPersonSt';

/* Services */

/**
 * Renders the AttendInPerson component
 *
 * @param {string} region - Region of the section
 * @param {object} sectionContent - Data for section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} AttendInPerson component
 */

const AttendInPerson = ({ sectionContent, lang, region }) => {
  const borderStyle = (index, array) =>
    index !== array.length - 1 ? '2.5px solid #CA4E2A' : 'none';

  return (
    <Box background="#FFF7E5">
      <Box
        width={{ base: '298px', sm: '298px', lg: '950px', xl: '1100px' }}
        mx="auto"
        py={{ base: '30px', sm: '30px' }}
      >
        {sectionContent?.map((section, index) => {
          if (section._modelApiKey === 'section_title') {
            return (
              <SectionTitle
                titleObj={section}
                titleDecoration={section?.titleDecoration}
                {...AttendInPersonSt[lang].title}
              />
            );
          }

          if (section?._modelApiKey === 'single_line_text') {
            return (
              <Box {...AttendInPersonSt[lang].subText}>{section?.text}</Box>
            );
          }

          if (section?._modelApiKey === 'artwork_text3_group_column') {
            return (
              <Box {...AttendInPersonSt[lang].descriptionText}>
                {section?.cards?.map((el, index, array) => (
                  <Box position="relative">
                    <Box
                      display="flex"
                      width={{ base: '100%', sm: '100%', xl: '300px' }}
                      height={{ base: '180px', sm: '180px', xl: '100%' }}
                      mx="auto"
                    >
                      <StructuredTextParser
                        textAlign="center"
                        paddingRight="20px"
                        region={region}
                        lang={lang}
                        str={render(el?.description)}
                      />
                    </Box>
                    <Box
                      position="absolute"
                      borderBottom={{
                        sm: borderStyle(index, array),
                        base: borderStyle(index, array),
                        lg: 'none',
                      }}
                      left="35%"
                      width="87px"
                      bottom="10%"
                    />
                    <Box
                      position="absolute"
                      right={{ base: '0', sm: '0', xl: -8 }}
                      top={0}
                      ml="20px"
                      borderRight={{
                        base: 'none',
                        sm: 'none',
                        lg: borderStyle(index, array),
                      }}
                      borderBottom={{
                        sm: borderStyle(index, array),
                        base: borderStyle(index, array),
                        lg: 'none',
                      }}
                      height="87px"
                      mt="20px"
                    />
                  </Box>
                ))}
              </Box>
            );
          }
        })}
      </Box>
      <style global jsx>{`
        #attend_in_person strong {
          font-size: 18px;
        }
      `}</style>
    </Box>
  );
};

export default AttendInPerson;
