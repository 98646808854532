/* Built In imports */
/* External Imports */
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Image } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

/* Internal Imports */
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

/**
 * Renders Gallery Component
 * @param {Obj} sectionContent
 * @returns {ReactElement} Gallery
 */
export default function Gallery({ sectionContent }) {
  const width = { base: '30px', md: '60px' };
  const iconSize = { base: '20px', md: '25px' };

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + '</span>';
    },
  };

  return (
    <Box py={{ base: '40px', md: '80px 10px' }}>
      <Box className="swiperContainer">
        <Swiper
          slidesPerView={'auto'}
          centeredSlides={true}
          loop={false}
          pagination={pagination}
          spaceBetween="10px"
          // speed={3000}
          // autoplay={{
          //   delay: 5000,
          //   disableOnInteraction: true,
          // }}
          breakpoints={{
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          navigation={{ nextEl: '.gallery-right', prevEl: '.gallery-left' }}
        >
          {sectionContent?.images?.map(image => {
            return (
              <SwiperSlide key={nanoid()}>
                <Box h="100%" overflow="hidden">
                  <Image
                    w="100%"
                    height="100%"
                    maxH="100%"
                    title={image?.title}
                    alt={image?.alt || image?.title || ''}
                    src={image?.url}
                    objectFit="cover"
                    objectPosition="center"
                    loading="lazy"
                  />
                </Box>
              </SwiperSlide>
            );
          })}
          <Box
            display="flex"
            pos="absolute"
            width="100%"
            top="43%"
            left="50%"
            zIndex="4"
            justifyContent="space-between"
            transform="translate(-50%, -50%)"
            maxW={{ base: '90%', sm: '90%' }}
          >
            <Button
              className="gallery-left arrow"
              rounded="full"
              height={width}
              width={width}
              p="20px"
              left={{ base: '0', sm: '18%', md: '19%', lg: '19%', xl: '20%' }}
              aria-label="gallery-left arrow"
              filter="drop-shadow(1.33px 1.288px 3.148px rgba(0, 0, 0, 0.02)) drop-shadow(5.852px 5.669px 6.519px rgba(0, 0, 0, 0.04)) drop-shadow(14.365px 13.916px 13px rgba(0, 0, 0, 0.05)) drop-shadow(27.666px 26.8px 25.481px rgba(0, 0, 0, 0.06)) drop-shadow(46.554px 45.097px 46.852px rgba(0, 0, 0, 0.08)) drop-shadow(71.825px 69.578px 80px rgba(0, 0, 0, 0.10))"
              _hover={{ bg: '#E19502' }}
            >
              <ChevronLeftIcon fontSize={iconSize} color="#000" />
            </Button>
            <Button
              className="gallery-right arrow"
              rounded="full"
              height={width}
              width={width}
              p="20px"
              right={{ base: '0', sm: '18%', md: '19%', lg: '19%', xl: '20%' }}
              aria-label="gallery-right arrow"
              filter="drop-shadow(1.33px 1.288px 3.148px rgba(0, 0, 0, 0.02)) drop-shadow(5.852px 5.669px 6.519px rgba(0, 0, 0, 0.04)) drop-shadow(14.365px 13.916px 13px rgba(0, 0, 0, 0.05)) drop-shadow(27.666px 26.8px 25.481px rgba(0, 0, 0, 0.06)) drop-shadow(46.554px 45.097px 46.852px rgba(0, 0, 0, 0.08)) drop-shadow(71.825px 69.578px 80px rgba(0, 0, 0, 0.10))"
              _hover={{ bg: '#E19502' }}
            >
              <ChevronRightIcon fontSize={iconSize} color="#000" />
            </Button>
          </Box>
        </Swiper>
      </Box>
      <style global jsx>
        {`
          .swiper {
            padding-bottom: 40px;
          }
          .swiper-pagination-fraction,
          .swiper-pagination-custom,
          .swiper-horizontal > .swiper-pagination-bullets,
          .swiper-pagination-bullets.swiper-pagination-horizontal {
            bottom: 0px;
          }
          .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            color: #000;
            opacity: 1;
            background: #d2bf8f;
          }
          .swiper-pagination-bullet-active {
            color: #fff;
            background: #02093c;
          }
          .mySwiper .swiper-slide-active {
            width: 690px;
            height: 412px;
            position: relative;
          }
          .mySwiper .swiper-slide {
            width: 690px;
            position: relative;
          }
          .mySwiper .swiper-slide-prev {
            max-height: 345px;
            height: 345px;
            width: 580px;
            margin: auto 0;
            overflow: hidden;
          }
          .mySwiper .swiper-slide-next {
            max-height: 345px;
            height: 345px;
            width: 580px;
            margin: auto 0;
            overflow: hidden;
          }
          .swiper-button-disabled {
            visibility: hidden;
          }
          @media (min-width: 1440px) {
            .mySwiper .swiper-slide {
              width: 50%;
              height: 50vh;
              position: relative;
            }
            .mySwiper .swiper-slide-prev {
              max-height: 45vh;
              margin: auto 0;
              overflow: hidden;
            }
            .mySwiper .swiper-slide-next {
              max-height: 45vh;
              margin: auto 0;
              overflow: hidden;
            }
          }
          @media (max-width: 768px) {
            .mySwiper .swiper-slide {
              width: 80%;
              height: 290px;
            }
            .mySwiper .swiper-slide-prev {
              max-height: 260px;
              margin: auto 0;
              overflow: hidden;
            }
            .mySwiper .swiper-slide-next {
              margin: auto 0;
              max-height: 260px;
              overflow: hidden;
            }
          }
          @media (max-width: 460px) {
            .mySwiper .swiper-slide {
              width: 80%;
              height: 190px;
            }
            .mySwiper .swiper-slide-prev {
              max-height: 160px;
              margin: auto 0;
              overflow: hidden;
            }
            .mySwiper .swiper-slide-next {
              margin: auto 0;
              max-height: 160px;
              overflow: hidden;
            }
          }
        `}
      </style>
    </Box>
  );
}
