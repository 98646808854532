import { Icon } from "@chakra-ui/react";

/**
 * Renders RightArrow Component
 * @param {Obj} props
 * @returns {ReactElement} RightArrow Component
 */
const RightArrow = ({ ...props }) => {
    return (
        <Icon width="14px" height="10px" viewBox="0 0 14px 10px" fill="none" {...props}>
            <path d="M9.57143 9L13 5M13 5L9.57143 1M13 5H1" stroke="#F6A15E" strokeWidth="1.83336" strokeLinecap="round" strokeLinejoin="round" />
        </Icon>
    );
};

export default RightArrow;
