/* Built In Imports */

/* External Imports */
import { Box, VStack } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@shi/Utility/StructuredTextParser';
import SectionTitle from '@shi/components/headings/SectionTitle';

/* Services */

/**
 * Renders the ContactUs component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} ContactUs component
 */

const ContactUs = ({ sectionContent, region, lang }) => {
  return (
    <>
      <Box background="#FFEBC7" textAlign="center" p={{ base: '50px 16px' }}>
        <Box>
          <VStack alignItems="center" justifyContent="center">
            <SectionTitle
              className="pricing-title"
              titleObj={sectionContent[0]}
            />
          </VStack>
        </Box>
        <Box
          fontFamily="FedraSansStd-book"
          m="0 auto"
          fontSize={{ base: '16px', md: '18px' }}
          color="#262523"
          p={{ base: '20px 20px 30px', md: '20px 0 30px' }}
          textAlign={{ base: 'center', md: 'center' }}
          width={{ base: '100%', md: '485px' }}
        >
          {sectionContent?.[1]?.text}
        </Box>
        <Box
          maxW={{ base: '100%', lg: '755px' }}
          m={{ base: '0 auto' }}
          background="#FFFFFF"
          color="#02093C"
          p={{ base: '10px', md: '10px' }}
          display="flex"
          minH={{ base: '106px', md: '106px' }}
          fontFamily="FedraSansStd-book"
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'center' }}
          className="shivanga-text contactdetail"
        >
          <Box
            w={{ base: '90%', md: '50%' }}
            className="contacttext"
            fontSize={{ base: '16px', md: '18px' }}
            borderRight={{ md: '0.5px solid #000000', base: 'none' }}
            borderBottom={{ base: '0.5px solid #000000', md: 'none' }}
          >
            <StructuredTextParser
              region={region}
              fontSize={{ base: '16px', md: '18px' }}
              lang={lang}
              theme="dark"
              textAlign="center"
              str={render(sectionContent?.[2]?.body)}
            />
            {/* {StructuredTextContent(sectionContent?.[2]?.body)} */}
          </Box>
          <Box
            w={{ base: '80%', md: '50%' }}
            p={{ base: '10px', md: '0 70px' }}
            fontSize={{ base: '16px', md: '18px' }}
            className="contactText"
            color="#02093C"
          >
            <StructuredTextParser
              fontSize={{ base: '16px', md: '18px' }}
              region={region}
              lang={lang}
              theme="dark"
              textAlign="center"
              str={render(sectionContent?.[3]?.body)}
            />

            {/* {StructuredTextContent(sectionContent?.[3]?.body)} */}
          </Box>
        </Box>
      </Box>
      <style global jsx>{`
        .contacttext p {
          padding-bottom: 0 !important;
        }
        .contacttext mark {
          background-color: #ffffff !important;
          color: #ca4e2a;
        }
        .contactText a {
          color: #ca4e2a;
          text-decoration: underline;
        }
      `}</style>
    </>
  );
};

export default ContactUs;
