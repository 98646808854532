/* Built In Imports */
import { Box } from '@chakra-ui/react';

/* External Imports */

/* Internal Imports */
/* Components */
import { RenderStructuredText } from '@shi/common/SectionUI';
import SectionTitle from '@shi/components/headings/SectionTitle';
import MidContainer from '@shi/components/Layout/MidContainer';
import MediaEmbedV2 from '@shi/components/media/MediaEmbedV2';
import CardProgram3ColumnGroup from '@shi/section/CardProgram3ColumnGroup';

/* Services */

/**
 * Renders the VidTxt component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} VidTxt component
 */

const VidText = ({ sectionContent, region, lang }) => {
  let video = sectionContent[1]?.video;

  return (
    <Box>
      <Box
        background={'#00052F'}
        position={'relative'}
        height={{
          base: video === null ? '200px' : '460px',
          sm: video === null ? '200px' : '400px',
          md: video === null ? '360px' : '460px',
        }}
        pt="25px"
      >
        <RenderStructuredText
          sectionContent={sectionContent[0]}
          region={region}
          lang={lang}
        />

        {video != null && (
          <MidContainer w="762px" pt="0">
            <Box
              width={{ base: '100%', lg: '100%' }}
              mx="auto"
              px={{ base: '16px', md: '0' }}
              position={'absolute'}
              left="0"
              marginTop={{
                sm: '10px',
                base: '10px',
                md: '30px',
              }}
            >
              <MediaEmbedV2
                sectionContent={sectionContent[1]}
                lang={lang}
                boxShadow="0 25px 40px 4px #1514143b"
              />
            </Box>
          </MidContainer>
        )}
      </Box>

      <Box
        marginTop={{
          base: (video === null ? '' : '-30px'),
          sm: (video === null ? '' : '-30px'),
          md: (video === null ? '' : '-30px'),
        }}
        paddingTop={{
          base: (video === null ? '40px' : '120px'),
          sm: (video === null ? '40px' : '120px'),
          md: (video === null ? '80px' : '250px'),
        }}
        background={'#FFEBC7'}
      >
        <SectionTitle
          titleObj={sectionContent[2]}
          titleDecoration={sectionContent[2]?.titleDecoration}
        />
        <CardProgram3ColumnGroup
          sectionContent={sectionContent[3]}
          region={region}
          lang={lang}
        />
      </Box>
    </Box>
  );
};

export default VidText;
