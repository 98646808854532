/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import CardInfo2ColumnGroup from '@shi/components/card/CardInfo2ColumnGroup';
import SectionTitle from '@shi/components/headings/SectionTitle';

/* Services */

/**
 * Renders the Activities component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} Activities component
 */

const Activities = ({ sectionContent, region, lang }) => {
  return (
    <Box
      // bg="#FFF7E5"
      bg="#FFEBC7"
      width="100%"
      mx="auto"
      mt={{
        base: '10px',
        sm: '10px',
        md: '50px',
      }}
      pb={{
        base: '50px',
        sm: '50px',
        md: '120px',
        lg: '120px',
      }}
    >
      {sectionContent?.map(section => {
        if (section?._modelApiKey === 'section_title') {
          return (
            <Box
              background={'#FFF7E5'}
              maxWidth={'870px'}
              mx={'auto'}
              pt={{
                base: '10px',
                sm: '10px',
                md: '40px',
                lg: '40px',
              }}
            >
              <SectionTitle
                titleObj={section}
                titleDecoration={section?.titleDecoration}
              />
            </Box>
          );
        } else if (section?._modelApiKey === 'card_info2_column_group') {
          return (
            <Box
              mt={{
                base: '-20px',
                sm: '-20px',
                md: '-20px',
              }}
            >
              <CardInfo2ColumnGroup
                sectionContent={section}
                region={region}
                lang={lang}
              />
            </Box>
          );
        }
      })}
    </Box>
  );
};

export default Activities;
