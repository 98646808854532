/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

/* Internal Imports */
/* Components */
import CardDetail from '@shi/components/card/CardDetail';

/* Services */

/**
 * Renders the CardProgram2ColumnGroup component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} CardProgram2ColumnGroup component
 */

const CardProgram2ColumnGroup = ({ sectionContent, region, language }) => {
  return (
    <Box
      display={'flex'}
      flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
      alignItems={'center'}
      justifyContent={{ base: 'center', sm: 'center', md: 'center' }}
      gap={{ base: '20px', sm: '20px', md: '51px' }}
      marginTop={{ base: '40px', sm: '40px', md: '60px' }}
      px={{ base: '16px' }}
    >
      {sectionContent?.cards?.map((card, index) => {
        return (
          <CardDetail
            key={index}
            card={card}
            region={region}
            language={language}
          />
        );
      })}
    </Box>
  );
};

export default CardProgram2ColumnGroup;
