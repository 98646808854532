import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h2: {
      fontSize: { base: '30px', lg: '36px' },
      //fontFamily='FedraSerifAStdBook'
      fontFamily: {
        base: lang ? config.isoLangFont[lang] : fontFamily,
        md: lang ? config.isoLangFont[lang] : fontFamily,
      },
      fontWeight: {
        base: lang ? '800' : '700',
        md: '100',
      },
      color: '#000000',
    },
    stFaText: {
      //  fontFamily:"FedraSansStd-book",
      fontSize: { base: '16px', lg: '20px' },
      // fontWeight: { base: 600, lg: 700 },
      lineHeight: { base: '20px', lg: '1.2' },
      color: '#28231E',
      fontFamily: isIndian ? config.isoLangFont[lang] : fontFamily,
      // fontFamily: config.isoLangFont[lang],
    },

    stQText: {
      fontFamily: isIndian ? config.isoLangFont[lang] : fontFamily,
      fontSize: { base: '16px', lg: '18px' },
      lineHeight: {
        base: '22px',
        lg: '22.5px',
      },
      // fontWeight: 600,
      color: '#28231E'
    },
    stFaAns: {
      lineHeight: { base: '24px', md: '28px' },
      fontWeight: 400,
      fontFamily: 'FedraSansStd-book',
      fontSize: '16px',
      //  fontFamily: config.isoLangFont[lang],
    },
  };
  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const FaqContentsSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  ar: commonLangStyle({
    lang: 'ar',
    stFaText: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },

    stQText: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
  }),
  id: commonLangStyle({ lang: 'id' }),
};

export default FaqContentsSt;
