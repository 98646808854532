import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    title: {
      fontWeight: '400',
      fontSize: { base: '30px', sm: '30', xl: '36px' },
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-A-medium',
    },
    subText: {
      textAlign: 'center',
      color: 'black',
      fontWeight: 400,
      my: {
        base: '20px',
        sm: '20px',
        md: '40px',
      },
      fontSize: '16px',
    },
    descriptionText: {
      display: 'flex',
      flexDirection: { base: 'column', sm: 'column', lg: 'row' },
      gap: { sm: '0px', base: '0px', xl: '100px' },
      textAlign: 'center',
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const AttendInPersonSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({
    lang: 'hi',
  }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  cn: commonLangStyle({ lang: 'cn' }),
  ar: commonLangStyle({
    lang: 'ar',
  }),
  id: commonLangStyle({ lang: 'id' }),
};
export default AttendInPersonSt;
