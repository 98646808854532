/* Built In Imports */

/* External Imports */
import { Box, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@shi/Utility/StructuredTextParser';
import SadhanaContentSt from '@shi/styleData/SadhanaContentSt';

/* Services */

/**
 * Renders the CardInfo2ColumnGroup component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} CardInfo2ColumnGroup component
 */
const CardInfo2ColumnGroup = ({ sectionContent, region, lang }) => {
  return (
    <Box>
      <Box
        bg="#FFF7E5"
        maxW="870px"
        width="100%"
        mx="auto"
        pt="10px"
        pb={{ base: '25px', md: '45px' }}
        px={{ base: '16px', md: '40px' }}
      >
        {sectionContent.cards.map((card, index) => {
          return (
            <Box
              id="tab-content-1"
              className="tab-content"
              pb="16px"
              borderBottom={{
                base: 'none',
                sm: 'none',
                md:
                  index === sectionContent.cards.length - 1
                    ? 'none'
                    : 'solid 1px #979797',
              }}
              m="0 auto"
              maxW={{ base: '90%', md: '790px' }}
              key={index}
            >
              <Box
                m={{ base: '35px 0 0 0' }}
                display="flex"
                flexDirection={{ base: 'column', sm: 'row' }}
              >
                <Box m={{ base: '0 0 40px 0', sm: 'unset' }}>
                  <LazyLoadImageComponent
                    src={
                      card?.thumbnail?.url ||
                      'https://static.sadhguru.org/d/46272/1701081803-shivanga-initiation.jpg'
                    }
                    alt={card?.thumbnail?.alt}
                    title={card?.thumbnail?.title}
                  />
                </Box>
                <Box
                  display="flex"
                  margin="0 0 0 15px"
                  w={{ base: '100%', md: '328px' }}
                  borderBottom="solid 1px #E4A100"
                  alignSelf="end"
                  padding={{ base: '0 0 7px 0' }}
                >
                  <a name="tab-1c" />
                  <Text
                    as="h4"
                    color="#000000"
                    fontSize={{ base: '20px', md: '28px' }}
                    fontFamily="FedraSansStd-A-medium"
                    // fontWeight="bold"
                    {...SadhanaContentSt[lang].h4}
                  >
                    <Text
                      as="span"
                      display={{ base: 'inline-block', md: 'block' }}
                      pr="10px"
                      textTransform={'uppercase'}
                    >
                      {card?.title}
                    </Text>
                    <Text as="span">{card?.titleSubtext}</Text>
                  </Text>
                </Box>
              </Box>
              <Box
                color="#000000"
                // fontSize={{ base: '16px', md: '18px' }}
                fontFamily="FedraSansStd-book"
                m={{
                  lg: '30px 0 0 0',
                  md: '20px 0 0 0',
                  sm: '20px 0 0 0',
                }}
                className="sadhana_list tabcontent"
              >
                <StructuredTextParser
                  style={'donateStyle'}
                  str={render(card?.description)}
                  lang={lang}
                  region={region}
                  mx="0"
                  px="0"
                  maxW="100%"
                  {...SadhanaContentSt[lang].cardInfo2Text}
                />
              </Box>
            </Box>
          );
        })}
      </Box>

      <style jsx global>{`
        .tab-content .chakra-collapse{
        margin-top: -25px;
        }
        @media screen and (max-width: 868px) {
          .tab-content .chakra-collapse {
            margin-top: -12px;
          }
        }
      `}</style>
    </Box>
  );
};

export default CardInfo2ColumnGroup;
