/* Built In Imports */

/* External Imports */
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import CompWithLeastStyle from '@shi/styleData/CompWithLeastStyle';

/* Services */


/**
 * Renders the BenefitsGroup component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} BenefitsGroup component
 */

const BenefitsGroup = ({ sectionContent, region, lang }) => {
  return (
    <>
      <Box marginTop={'-30px'}>
        <Box
          w={{ base: '100%', md: '850px' }}
          m={{ base: '0 auto', md: '0 auto' }}
          p={{ base: '55px 0 25px 0' }}
          textAlign={{ base: 'center' }}
          display={{ base: 'flex', md: 'block' }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Box
            display={{ base: 'flex' }}
            flexDirection={{ base: 'column', sm: 'row' }}
            justifyContent={{ base: 'center', md: 'space-between' }}
            flexWrap={{ base: 'unset', sm: 'wrap', xl: 'unset' }}
            m="0 auto"
          >
            {sectionContent?.benefits?.map(data => {
              return (
                <Flex
                  alignItems={{ base: 'center', md: 'start' }}
                  justifyContent={{ base: 'start' }}
                  flexDirection="column"
                  // w={{ base: '100%', sm: '48%', lg: '50%', xl: '25%' }}
                  p="0 10px"
                  key={nanoid}
                  width={{ base: '308px', md: '254px' }}
                >
                  <Image
                    width="100%"
                    loading="lazy"
                    alt={data?.image?.alt}
                    title={data?.image?.title}
                    maxW="200px"
                    maxH="200px"
                    src={data?.image?.url}
                    m={{ base: '0 auto 22px auto' }}
                    borderRadius={
                      sectionContent.style === 'default' ? '50%' : ''
                    }
                  />
                  <Text
                    as="h6"
                    color="#000000"
                    fontFamily="FedraSansStd-book"
                    fontSize={{ base: '16px', md: '18px' }}
                    m={{ base: '0 auto 35px auto', lg: '0 auto 15px' }}
                    w={{ base: '100%', md: '234px' }}
                    // w="100%"
                    lineHeight="26px"
                    textAlign={'center'}
                    {...CompWithLeastStyle[lang].benefitsGroup}
                  >
                    {data?.text}
                  </Text>
                </Flex>
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BenefitsGroup;
