/* Built In Imports */
import { Box, Image } from '@chakra-ui/react';

/* External Imports */

/* Internal Imports */
/* Components */
import SectionTitle from '@shi/components/headings/SectionTitle';

/* Services */

/**
 *
 * @param {*} param0
 * @returns
 */
const Schedule = ({ sectionContent, lang }) => {
  return (
    <Box
      // height={{
      //     base: '1047px',
      //     sm: '1047px',
      //     md: '603px',
      // }}
      paddingTop={{
        base: '52px',
        sm: '52px',
        md: '69px',
      }}
      paddingBottom={{
        base: '79px',
        sm: '79px',
        md: '79px',
      }}
    >
      {sectionContent?.map((section, index) => {
        if (section?._modelApiKey === 'section_title') {
          return (
            <SectionTitle
              color="white"
              fontfamily="FedraSansStd-A-medium"
              titleObj={section}
              titleDecoration={section?.titleDecoration}
            />
          );
        } else if (
          section?._modelApiKey === 'msr_event_schedule_image_card_group'
        ) {
          return (
            <Box
              display="flex"
              flexWrap={'wrap'}
              justifyContent="center"
              alignItems={{
                base: 'center',
                sm: 'center',
                md: 'flex-start',
              }}
              flexDirection={{
                base: 'column',
                sm: 'column',
                md: 'row',
              }}
              gap={{
                base: '35px',
                sm: '35px',
                md: '90px',
              }}
              marginTop={{
                base: '36px',
                sm: '36px',
                md: '40px',
              }}
            >
              {section?.scheduleCards?.map((card, index) => {
                return (
                  <Box
                    display={'flex'}
                    flexDirection={{
                      base: 'row',
                      sm: 'row',
                      md: 'column',
                    }}
                    gap={{
                      base: '25px',
                      sm: '25px',
                      md: '30px',
                    }}
                    alignItems={'center'}
                    maxWidth={{
                      base: 'auto',
                      sm: 'auto',
                      md: '190px',
                      lg: '190px',
                    }}
                  >
                    <Image
                      src={card?.image?.url}
                      height={{
                        base: '126px',
                        sm: '126px',
                        md: '166px',
                        lg: '166px',
                      }}
                      w={'128px'}
                    />
                    <Box
                      textAlign={{
                        base: 'left ',
                        sm: 'left',
                        md: 'center',
                      }}
                      color={'white'}
                      width={{
                        base: '163px',
                        sm: '163px',
                        md: '160px',
                        lg: '160px',
                      }}
                    >
                      {card?.title}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          );
        }
      })}
    </Box>
  );
};

export default Schedule;
