/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@shi/Utility/StructuredTextParser';
import SimpleButton from '@shi/components/button/SimpleButton';
import DonateSt from '@shi/styleData/DonateSt';

/* Services */
/* Styles */

/**
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data for Section
 * @param {Str} region
 * @param {Str} lang
 * @returns {ReactElement} DonateCard component
 */

const DonateCard = ({ sectionContent, region, lang }) => {
  return (
    <Box
      px={{ base: '23px', sm: '23px', xl: '32px' }}
      py={{ base: '24px', sm: '24px', xl: '28px' }}
      background={'#FFF9ED'}
      w={{ base: '328px', sm: '328px', xl: '386px' }}
      //   height={{ base: '264px', sm: '264px', xl: '264px' }}
      pb="30px"
    >
      {sectionContent?.cards?.map((card, index) => {
        return (
          <Box>
            <Box {...DonateSt[lang].title}>{card?.title}</Box>
            <Box
              mt={{ base: '13px', sm: '13px', xl: '13px' }}
              {...DonateSt[lang].descriptionText}
            >
              <StructuredTextParser
                str={render(card?.description)}
                fontSize="14px"
                fontFamily="FedraSansStd-book"
                lang={lang}
                region={region}
                px="0"
              />
            </Box>
            <Box
              display={'flex'}
              gap={{ base: '10px', sm: '10px', xl: '10px' }}
              mt={{ base: '31px', sm: '31px', xl: '24px' }}
            >
              <Box>

                <SimpleButton
                  textAlign={'center'}
                  style={{
                    border: '1px solid black',
                    buttonStyle: 'Orange',
                    buttonText: card?.buttonText,
                    linkUrl: card?.buttonLink,
                  }}
                  region={region}
                  fontSize="14px"
                  minW={{ base: '131px', sm: '131px' }}
                  mb="0"
                  size={{ base: 'xs', sm: 'sm' }}
                  maxH="44px"
                  padding="10px 29px"
                />
              </Box>
              <Box>
                <SimpleButton
                  fontSize="14px"
                  padding={{ base: '14px 20px !important', sm: '14px 29px' }}
                  mb="0"
                  style={{
                    border: '1px solid black',
                    color: 'black',
                    buttonStyle: 'normal',
                    buttonText: card?.secondCtaLabel,
                    linkUrl: card?.secondCtaLink,
                  }}
                  region={region}
                  lang={lang}
                />
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default DonateCard;
