/* Built In Imports */
import NextLink from 'next/link';
import { useEffect, useState } from 'react';

/* External Imports */
import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Heading,
  Hide,
  Link,
  Show,
  Stack,
  Text,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@shi/Utility/StructuredTextParser';
import { isOpenInNewTab, refineUrlDomain } from '@shi/components/Utility/utils';
import SimpleButton from '@shi/components/button/SimpleButton';
import RightArrow from '@shi/components/icon/RightArrow';
import TopBannerSt from '@shi/styleData/TopBannerSt';

/* Styles */
import styles from '@shi/components/banner/ShivangaBanner.module.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/* Services */
import { searchByFieldId } from '@shi/Utility/Shared/SharedService';

/**
 * Renders the ShivangaBanner component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} ShivangaBanner component
 */
const HeaderBanner = ({ sectionContent, lang, region }) => {
  const [isSlider, setIsSlider] = useState(false);
  let iOS = false;
  //
  const bannerData = sectionContent;
  const isLbSliderAvailable =
    bannerData?.[bannerData.length - 1]?._modelApiKey ===
    'linga_bhairavi_single_line_cta_slider';

  const showDetails = bannerData.find(
    el => el._modelApiKey === 'samskriti_benefit'
  );
  const quoteBanner = bannerData.find(el => el._modelApiKey === 'quote');
  const desktopVideo = sectionContent.find(el => el.fieldId === 'banner-video');
  const mobVideo = sectionContent.find(el => el.fieldId === 'banner-video-mob');

  const iPhoneScreenHide = () => {
    var elVideo = document.getElementById('hideiOSVideo');
    iOS = /iPad|iPhone|iPod/.test(navigator?.userAgent) && !window.MSStream;
    if (iOS) {
      elVideo.style.display = 'none';
    }
  };

  useEffect(() => {
    iPhoneScreenHide();
    if (isLbSliderAvailable) {
      setIsSlider(true);
    }
  }, []);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => (
      <Box
        style={{
          borderRadius: '10px',
          position: 'absolute',
          right: 0,
          width: '70px',
          display: 'flex',
        }}
        top={{ base: '50px', md: '9px' }}
        left={{
          base: '46%',
          sm: '48%',
          md: '-78px',
          lg: '-58px',
        }}
        className={styles.homeBannerSlickDot}
      >
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </Box>
    ),
    customPaging: i => (
      <Box
        marginTop={{
          base: '7px',
          sm: '7x',
          md: '0px',
          lg: '0px',

        }}
        width="8px" height="8px" background="#C0BEBE" rounded="full"></Box>
    ),
  };

  return (
    <>
      {/* Banner image */}
      <Box>
        <style>
          {`
            .slick-dots li .dot {
              background-color: #C0BEBE;
            }
            .slick-dots li {
              margin:0 !important;
            }
            .slick-dots .slick-active div {
              background-color: #F6A15E !important;
            }
            `}
        </style>
        <Box
          overflow="hidden"
          backgroundImage={{
            base: `url('${searchByFieldId(sectionContent, 'bg-image')?.mobileImage?.url
              }')`,
            lg: `url('${searchByFieldId(sectionContent, 'bg-image')?.image?.url
              }')`,
            md: `url('${searchByFieldId(sectionContent, 'bg-image')?.image?.url
              }')`,
          }}
          w={{ base: '100%', md: '100%' }}
          height={{ base: '100%', md: '100vh' }}
          position="relative"
          textAlign="center"
          backgroundSize={{ base: '100% 355px', sm: '100% 455px', md: 'cover' }}
          backgroundPosition="top"
          backgroundColor="#000"
          display="flex"
          justifyContent="center"
          alignItems={{ md: 'center', base: 'flex-end' }}
          flexDir="column"
          backgroundRepeat="no-repeat"
        >
          <Box
            pos="absolute"
            top="0"
            width="100%"
            height="100%"
            bottom="0"
            zIndex={0}
            id="hideiOSVideo"
          >
            <Box display={{ base: 'block', sm: 'none' }}>
              {mobVideo?.text && (
                <video width="100%" height="100%" loop muted autoPlay>
                  <source
                    src={
                      searchByFieldId(sectionContent, 'banner-video-mob')?.text
                    }
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              )}
            </Box>
            <Box display={{ base: 'none', sm: 'block' }}>
              {desktopVideo?.text && (
                <video width="100%" height="100%" loop muted autoPlay>
                  <source
                    src={searchByFieldId(sectionContent, 'banner-video')?.text}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              )}
            </Box>
          </Box>

          <Box
            pos="relative"
            zIndex={2}
            w={{ base: '100%', xl: '100%' }}
            m={{ base: '245px auto 0 auto', md: '0 auto' }}
            p={{ base: '40px 0 0px 0', lg: '35px 0' }}
            background={{
              md: 'transparent',
              base: 'linear-gradient(0deg, #000 88.98%, rgba(0, 0, 0, 0.00) 100%)',
            }}
          >
            <Stack spacing={3} maxW={550} h="100%" mx="auto">
              {searchByFieldId(sectionContent, 'banner-title') && (
                <Heading
                  as="h1"
                  color="#FFA735"
                  fontFamily="FedraSansStd-A-medium"
                  textAlign="center"
                  {...TopBannerSt[lang].h1}
                >
                  {searchByFieldId(sectionContent, 'banner-title').text}
                </Heading>
              )}
              {searchByFieldId(sectionContent, 'banner-title-big') && (
                <Heading
                  as="h1"
                  color="#FFA735"
                  fontFamily="FedraSansStd-A-medium"
                  textAlign="center"
                  {...TopBannerSt[lang].h1}
                  pb={2}
                >
                  {searchByFieldId(sectionContent, 'banner-title-big').text}
                </Heading>
              )}
              {searchByFieldId(sectionContent, 'banner-subtitle') && (
                <Heading
                  as="h2"
                  color="#fff"
                  fontFamily="FedraSansStd-A-medium"
                  textAlign="center"
                  pb={2}
                  {...TopBannerSt[lang].titleSubtext}
                >
                  {searchByFieldId(sectionContent, 'banner-subtitle').text}
                </Heading>
              )}
              {searchByFieldId(sectionContent, 'banner-subtext') && (
                <Text
                  color="#F5E3B5"
                  fontFamily="FedraSansStd-book"
                  pb={{ base: 2, md: 4 }}
                  px={4}
                  {...TopBannerSt[lang].bannerDes}
                >
                  {searchByFieldId(sectionContent, 'banner-subtext').text}
                </Text>
              )}

              {searchByFieldId(sectionContent, 'banner-subtext-yellow') && (
                <Text
                  color="#F5E3B5"
                  fontFamily="FedraSansStd-book"
                  pb={{ base: 2, md: 4 }}
                  px={4}
                  {...TopBannerSt[lang].bannerDes}
                >
                  {
                    searchByFieldId(sectionContent, 'banner-subtext-yellow')
                      .text
                  }
                </Text>
              )}

              {searchByFieldId(sectionContent, 'banner-subtext-white') && (
                <Text
                  width={{ base: '100%', md: '100%', lg: '502px' }}
                  color="#fff"
                  pb={{ base: 2, md: 6 }}
                  px={4}
                  mx="auto"
                  {...TopBannerSt[lang].p}
                >
                  {searchByFieldId(sectionContent, 'banner-subtext-white').text}
                </Text>
              )}
              {searchByFieldId(sectionContent, 'banner-subtext-italic') && (
                <Text
                  color="#fff"
                  pb={{ base: 2, md: 6 }}
                  px={4}
                  {...TopBannerSt[lang].pItalic}
                >
                  {
                    searchByFieldId(sectionContent, 'banner-subtext-italic')
                      .text
                  }
                </Text>
              )}

              {bannerData?.[0]?._modelApiKey === 'simple_button' && (
                <Box
                  py={{ base: 3, md: '0' }}
                  display="flex"
                  justifyContent="center"
                // bg={{ base: '#171001', sm: 'none' }}
                >
                  <SimpleButton
                    style={bannerData?.[0]}
                    background="#CA4E2A"
                    minW="217px"
                    border="none"
                    borderRadius="3px"
                    mb="0"
                    fontSize="16px"
                    mt="0"
                    region={region}
                    lang={lang}
                    _hover={{
                      border: 'none',
                      textDecor: 'none',
                      bg: '#983C21',
                    }}
                    fontFamily="FedraSansStd-medium"
                  />
                </Box>
              )}
            </Stack>
          </Box>

          {isSlider && isLbSliderAvailable && (
            <Flex
              w="100%"
              h="auto"
              alignItems={{ base: 'flex-start', md: 'center' }}
              justifyContent={{ base: 'flex-start', md: 'center' }}
              background="linear-gradient(90deg, rgba(0,0,0,0.5928965336134453) 2%, rgba(0,0,0,0) 75%)"
              pos="absolute"
              top="0"
              zIndex={9}
            >
              <Box
                w={{
                  base: '100%',
                  sm: '100%',
                  md: '44%',
                }}
                pt="14.5px"
                pb="24px"
                h="auto"
                pl={{ base: "0", md: "100px" }}
                maxW="1440px"
              >
                <Slider {...settings}>
                  {sectionContent[6]?.cards?.map(item => {
                    return (
                      <Box
                        w={{ base: '300px', md: '480px' }}
                        px={{ base: '0', md: '12px' }}
                        style={{ height: '19px' }}
                        key={nanoid()}
                        display="flex"
                        m={{ base: '0 14px', md: '0 10px' }}
                      // alignItems="center"
                      >
                        <NextLink
                          href={refineUrlDomain(item?.linkUrl, region, lang)}
                          passHref
                          target={isOpenInNewTab(item?.linkUrl) ? '_blank' : ''}
                          legacyBehavior
                        >
                          <Link _focus={{ boxShadow: 'none' }}>
                            <Box
                              lineHeight="19px"
                              fontStyle="normal"
                              fontWeight="500"
                              letterSpacing="0.025em"
                              color="#fff"
                              textAlign={{ base: 'left', lg: 'center' }}
                              display="flex"
                            >
                              <Box
                                mr={{ base: '8px', md: '14px' }}
                                w={{ base: 'max(270px, 50%)', lg: '100%' }}
                                display="flex"
                                gridGap="10px"
                                flexFlow="nowrap"
                              >
                                <Show above="md">
                                  <Box
                                    display={'flex'}
                                    flexDirection={{
                                      base: 'column',
                                      lg: 'row',
                                    }}
                                  >
                                    <Text
                                      fontWeight="500"
                                      textDecorationLine="underline"
                                      fontFamily="FedraSansStd-A-medium"
                                      display={{
                                        base: 'block',
                                        lg: 'inline-block',
                                      }}
                                    >
                                      {item?.buttonText} {item?.subtextDate}
                                    </Text>
                                  </Box>
                                </Show>
                                <Hide above="md">
                                  <Box
                                    display={'flex'}
                                    flexDirection={{
                                      base: 'column',
                                      lg: 'row',
                                    }}
                                  >
                                    <Text
                                      fontWeight="500"
                                      textDecorationLine="underline"
                                      fontFamily="FedraSansStd-A-medium"
                                      display={{
                                        base: 'block',
                                        lg: 'inline-block',
                                      }}
                                    >
                                      {item?.buttonText}
                                    </Text>
                                    <Text
                                      textDecoration={{
                                        base: 'none',
                                        lg: 'underline',
                                      }}
                                      mt={{
                                        base: '10px',
                                        md: '0',
                                      }}
                                      ml={{
                                        base: '0',
                                        md: '3px',
                                      }}
                                    >
                                      {item?.subtextDate}
                                    </Text>
                                  </Box>
                                </Hide>
                                <RightArrow mt="4px" />
                                {/* <BsArrowRight style={{ color: '#F6A15E' }} /> */}
                              </Box>
                            </Box>
                          </Link>
                        </NextLink>
                      </Box>
                    );
                  })}
                </Slider>
              </Box>
              <Box
                pos="absolute"
                right={{ base: '2%', md: '10%' }}
                top={{ base: '20px', md: '1px' }}
                w="40px"
                h="100%"
                alignSelf="flex-end !important"
                className="pinky"
                color="#fff"
                textAlign="center"
                zIndex={5}
                transform="translate(0px, 10px)"
                _hover={{
                  color: '#E19502',
                }}
              >
                <CloseIcon
                  cursor="pointer"
                  _hover={{ color: '#E19502' }}
                  onClick={() => {
                    setIsSlider(false);
                  }}
                />
              </Box>
            </Flex>
          )}
        </Box>
      </Box>
      {/* Banner Quote section  */}
      {quoteBanner && (
        <Box
          background="#000000"
          p={{ lg: '20px 15px', base: '20px 15px 40px 15px' }}
          minH={{ base: '240px' }}
        >
          <Box
            maxW={{ base: '328px', md: '475px' }}
            margin="0 auto"
            textAlign="center"
            display="flex"
            alignItems="center"
            flexDir="column"
            justifyContent="center"
          >
            <StructuredTextParser
              color="#C1C1C1"
              fontSize={{ base: '16px', md: '18px' }}
              textAlign={{ base: 'center', sm: 'center' }}
              margin="20px 0 20px 0"
              lineHeight="28px"
              maxW={460}
              width="100%"
              fontFamily="FedraSansStd-book"
              region={region}
              lang={lang}
              minH={{ base: '70px', md: '70px' }}
              str={render(bannerData?.[5]?.body)}
            />
            <LazyLoadImageComponent
              src={bannerData?.[6]?.image?.url}
              title={bannerData?.[6]?.image?.title}
              alt={bannerData?.[6]?.image?.alt}
            />
          </Box>
        </Box>
      )}

      {/* Banner Des section  */}
      {showDetails && (
        <Box background="#FFEBC7" p={{ base: '40px 20px', md: '40px 0 5px 0' }}>
          <Box w={{ base: 'auto', lg: '710px' }} m={{ base: '', lg: '0 auto' }}>
            {bannerData?.map((el, index) => {
              if (el._modelApiKey === 'samskriti_benefit') {
                return (
                  <Box display="flex" alignItems="center" pt="10px" key={index}>
                    <Box width="30px" display="inline-block" mr="12px">
                      <LazyLoadImageComponent
                        src={el?.image?.url}
                        alt={el?.image?.alt || ''}
                        pt="3px"
                      />
                    </Box>
                    <StructuredTextParser
                      str={render(el?.description)}
                      fontFamily="FedraSansStd-book"
                      my="0px"
                      region={region}
                      lang={lang}
                      className="shivanga-text"
                    />
                  </Box>
                );
              } else null;
            })}
          </Box>
          <style global jsx>{`
            .whatisdata p {
              text-align: left;
            }
            .whatisdata a {
              text-decoration: none;
              color: #ca4e2a;
              font-weight: bold;
            }
            .whatisdata p mark {
              color: #ca4e2a;
              background: transparent;
            }
            .shivanga-text p {
              padding: 0 0 10px 0;
              font-family: 'FedraSansStd-book' !important;
            }
            .shivanga-text a:hover span {
              background-color: #cf4824;
              color: white;
              background-image: linear-gradient(120deg, #cf4824, #cf4824);
              background-size: 100% 100%;
              transition: all 0.3s ease-in-out;
            }
            .shivanga-text a:hover span {
              color: white;
              text-decoration: underline;
            }
            .slick-dots .slick-active div {
              background: #f6a15e !important;
            }
            .bkg {
              display: flex;
              align-items: stretch;
              justify-content: center;
              height: calc(100vh - 160px);
              min-height: 650px;
              width: 100%;
              overflow: hidden;
              margin: 0 auto;
            }

            .vid {
              display: block;
              object-fit: cover;
              object-position: top;
              width: 100%;
              height: calc(100vh - 160px);
              min-height: 650px;
            }
            @media (max-width: 767px) {
              .bkg,
              .vid {
                height: calc(100vh - 74px);
              }
            }
            @media (max-width: 1023px) {
              .bkg,
              .vid {
                height: calc(100vh - 74px);
              }
            }
          `}</style>
        </Box>
      )}
    </>
  );
};

export default HeaderBanner;
