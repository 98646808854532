/* Built In Imports */
/* External Imports */
import { Box, Image } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';

/* Services */

/**
 * Renders the SectionTitle component.
 *
 * @param titleObj.titleObj
 * @param {object} titleObj component.
 * @param {object} rest some more data
 * @param titleObj.section
 * @param titleObj.loadInit
 * @returns {ReactElement} SectionTitle component.
 */
export default function SectionTitle({ titleObj, loadInit, ...props }) {
  // console.log('title obj', titleObj)
  const titleColor = {
    'shivanga-flower-white': '#FCF6E2',
    'shivanga-flower-black': '#000',
    'shivanga-trishul': '#000',
    default: '#FCF6E2',
    'no-decoration': '#02026c',
  };

  const maxW = {
    'shivanga-flower-white': '470px',
    'shivanga-flower-black': '470px',
    'shivanga-trishul': '470px',
    default: '470px',
    'no-decoration': '770px',
  };

  return (
    <>
      {(titleObj?.titleText || titleObj?.title) && (
        <Box
          className={`title-${titleObj?.titleDecoration || 'default'}`}
          maxW={props.w || maxW[titleObj?.titleDecoration || '']}
          mx="auto"
          fontFamily={'FedraSansStd-A-medium'}
          fontSize={{
            base: titleObj?.fontSize || '30px',
            md: titleObj?.fontSize || '36px',
          }}
          lineHeight={{ base: '36px', md: '44px' }}
          color={titleObj?.color || titleColor[titleObj?.titleDecoration || '']}
          textAlign="center"
          mt={{
            base: props.mt || '30px',
            md: props.mt || '30px',
          }}
          fontWeight="500"
          {...props}
        >
          <Box as="h2" pb="10px">
            {titleObj?.title || titleObj?.titleText}
          </Box>
          <Box
            textAlign="center"
            mb="15px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {RenderBorder(
              props.titleDecoration != undefined
                ? props.titleDecoration
                : titleObj?.titleDecoration,
              loadInit
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

const RenderBorder = (style, loadInit) => {
  style = style || 'default';
  const imgPath = {
    'shivanga-flower-white': '1713160773-vector.svg',
    'shivanga-flower-black': '1713160773-vector.svg',
    'shivanga-trishul': '1647429933-shivang_separate_border.png',
    default: '1647429933-shivang_separate_border.png',
    'no-decoration': '',
  };
  const minH = {
    'no-decoration': '24px',
    default: '24px',
  };
  if (loadInit) {
    return (
      imgPath[style] && (
        <Image
          alt="divider"
          loading="lazy"
          title="divider"
          minHeight={minH[style]}
          src={
            imgPath[style]?.indexOf('https://') < 0
              ? `${config.staticPath}/d/46272/${imgPath[style]}`
              : imgPath[style]
          }
        />
      )
    );
  }

  return (
    imgPath[style] && (
      <LazyLoadImageComponent
        alt="divider"
        loading="lazy"
        title="divider"
        minHeight={minH[style]}
        src={
          imgPath[style]?.indexOf('https://') < 0
            ? `${config.staticPath}/d/46272/${imgPath[style]}`
            : imgPath[style]
        }
      />
    )
  );
};
