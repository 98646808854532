/* Built In Imports */

/* External Imports */
import { Box, useMediaQuery } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SectionTitle from '@shi/components/headings/SectionTitle';
import UpcomingShivangaSpurthiSt from '@shi/styleData/UpcomingShivangaSpurthiSt';

/* Services */

/**
 * Renders the UpcomingShivangaSpurthi component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} UpcomingShivangaSpurthi component
 */

const UpcomingShivangaSpurthi = ({ sectionContent, lang }) => {
  // console.log("upcoming shivanga spurthi", sectionContent)
  const [isSmallScreen] = useMediaQuery(
    '(min-width: 275px) and (max-width: 330px)'
  );

  return (
    <Box
      paddingTop={{
        base: '0px',
        sm: '0px',
        md: '0px',
        lg: '10px',
      }}
      paddingBottom={{
        base: '54px',
        sm: '54px',
        md: '54px',
        lg: '48px',
      }}
    >
      {sectionContent?.map((section, index) => {
        if (section?._modelApiKey === 'section_title') {
          return (
            <SectionTitle
              color="red"
              titleObj={section}
              titleDecoration={section?.titleDecoration}
              {...UpcomingShivangaSpurthiSt[lang].h1}
            />
          );
        } else if (section?._modelApiKey === 'card_custom_card_group') {
          return (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              marginTop={{
                base: '36px',
                sm: '36px',
                md: '40px',
              }}
              flexWrap={'wrap'}
            >
              {section?.cards?.map((el, index, array) => {
                return (
                  <Box
                    borderRight={{
                      base:
                        index !== array.length - 1 && index !== array.length - 3
                          ? '2px solid #CA4E2A'
                          : index === 0 ? '2px solid #CA4E2A' : 'none',
                      sm:
                        index !== array.length - 1 && index !== array.length - 3
                          ? '2px solid #CA4E2A'
                          : index === 0 ? '2px solid #CA4E2A' : 'none',
                      md:
                        index !== array.length - 1
                          ? '2px solid #CA4E2A'
                          : 'none',
                    }}
                    color={'white'}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={
                      isSmallScreen
                        ? '100px'
                        : {
                          base: '108px',
                          sm: '108px',
                          lg: '162px',
                        }
                    }
                    lineHeight={{
                      base: '36px',
                      sm: '36px',
                      lg: '47px',
                    }}
                    marginTop={{
                      sm: index === 3 || index === 4 ? '40px' : '0',
                      base: index === 3 || index === 4 ? '40px' : '0',
                      md: '0px',
                    }}
                  >
                    <Box {...UpcomingShivangaSpurthiSt[lang].p}>
                      {' '}
                      {el?.title}
                    </Box>
                    <Box {...UpcomingShivangaSpurthiSt[lang].smallText}>
                      {' '}
                      {el?.sessions}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          );
        }
      })}
    </Box>
  );
};

export default UpcomingShivangaSpurthi;
