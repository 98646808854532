/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Flex, Heading, Link } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@shi/Utility/StructuredTextParser';
import { isOpenInNewTab, refineUrlDomain } from '@shi/components/Utility/utils';
import CardProgram3St from '@shi/styleData/CardProgram3St';

/* Services */

// Utils

/**
 * Renders the CardProgram3ColumnGroup on sectionId
 * @param {Object} sectionContent- Data for section
 * @param {Object} section
 * @returns {ReactElement} Designs CardProgram3ColumnGroup for children component
 */

const CardProgram3ColumnGroup = ({ sectionContent, lang, region }) => {

  return (
    <Box px="16px" pt={3} pb={20}>
      <Flex
        gridGap={{ base: '20px', md: '30px' }}
        flexDir="column"
        justifyContent="center"
      >
        {sectionContent?.cards?.map((card, index) => {
          return (
            <Flex
              key={nanoid()}
              flexWrap={{ base: 'wrap', md: 'nowrap' }}
              h={{ base: 'auto', md: '320px' }}
              alignItems="center"
              justifyContent="center"
              w={{ base: '100%', sm: '328px', md: 'auto' }}
              mx={{ base: 'auto', md: '0' }}
              flexDir={{
                base: 'column',
                md: index % 2 === 0 ? 'row' : 'row-reverse',
              }}
            >
              <LazyLoadImageComponent
                w={{ base: '100%', md: '350px', lg: '386px' }}
                src={card?.thumbnail?.url || ''}
                alt={card?.thumbnail?.alt || ''}
                height={{ base: '175px', md: '100%' }}
                bg="lightgray 50% / cover no-repeat"
                objectFit="cover"
              />
              <Box py={{ base: 0, md: '18px' }} h="100%">
                <Flex
                  background="#FFF9ED"
                  height="100%"
                  w={{ base: '100%', md: '386px' }}
                  direction="column"
                  p={{
                    base: '22px 22px 40px',
                    sm: '22px 22px 40px',
                    md: '30px 30px 40px 30px',
                  }}
                  justifyContent="space-between"
                >
                  <Box>
                    <Heading as="h3" {...CardProgram3St[lang].h3}>
                      {card?.title}
                    </Heading>
                    <StructuredTextParser
                      // theme="dark"
                      region={region}
                      lang={lang}
                      px="0"
                      id="programDes"
                      str={render(card.description)}
                      {...CardProgram3St[lang].description}
                      sx={{
                        p: { fontSize: '16px' },
                      }}
                    />
                  </Box>
                  <Flex gap="10px" mt={{ base: '25px', md: '20px' }}>
                    {card.buttonText && (
                      <NextLink
                        href={
                          refineUrlDomain(card.buttonLink || '#', region,
                            lang)
                        }
                        passHref
                        legacyBehavior
                      >
                        <Link
                          borderRadius="2.484px"
                          background="#CA4E2A"
                          padding={{ base: '13px 15px', sm: '10px 20px' }}
                          // mt="auto"
                          maxH="44px"
                          width={{
                            base: '100%',
                            sm: 'auto',
                            md: 'auto',
                            lg: "134px"
                          }}
                          textDecor="none"
                          size={{ base: 'xs', md: 'sm' }}
                          _hover={{ textDecor: 'none', bg: '#983C21' }}
                          target={
                            isOpenInNewTab(card.buttonLink) ? '_blank' : ''
                          }
                          _focus={{ boxShadow: 'none' }}
                          textAlign="center"
                          {...CardProgram3St[lang].solidButton}
                        >
                          {card.buttonText}
                        </Link>
                      </NextLink>
                    )}
                    {card.secondCtaLabel && (
                      <NextLink
                        href={refineUrlDomain(
                          card.secondCtaLink || '#',
                          region,
                          lang
                        )}
                        passHref
                        legacyBehavior
                      >
                        <Link
                          // ml="10px"
                          href={
                            (refineUrlDomain(card.secondCtaLink || '#', lang),
                              region,
                              lang)
                          }
                          borderRadius="2.484px"
                          // background="#CA4E2A"
                          padding={{ base: '13px 15px', sm: '10px 20px' }}
                          // mt="auto"
                          maxH="44px"
                          width={{
                            base: card.buttonText ? '100%' : '50%',
                            sm: 'auto',
                            md: 'auto',
                            lg: "131px"
                          }}
                          textAlign="center"
                          variant="outline"
                          border="1px solid #28231E"
                          textDecor="none"
                          size={{ base: 'xs', md: 'sm' }}
                          _hover={{
                            textDecor: 'none',
                            bg: '#E19502',
                            border: '1px solid #E19502',
                          }}
                          target={
                            isOpenInNewTab(card.secondCtaLink) ? '_blank' : ''
                          }
                          {...CardProgram3St[lang].solidButton}
                          color="#000"
                          _focus={{ boxShadow: 'none' }}
                        >
                          {card.secondCtaLabel}
                        </Link>
                      </NextLink>
                    )}
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          );
        })}
        <style>
          {`
            #programDes p{
              margin-bottom:10px;
            }
            `}
        </style>
      </Flex>
    </Box>
  );
};

export default CardProgram3ColumnGroup;
