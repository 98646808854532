import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h1: {
      fontSize: { lg: '52px', md: '40px', base: '28px' },
      fontFamily: 'FedraSansStd-A-medium',
      // fontWeight: '700',
    },
    testimonialText: {
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-book',
      fontSize: '16px',
      fontStyle: 'normal',
      lineHeight: '26px',
    },
    testimonialName: {
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSerifAStdBook',
      fontSize: '18px',
      lineHeight: '26px',
    },
    testimonialDes: {
      color: '#AF1F22',
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-book',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '155%',
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const TestimonialSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({
    lang: 'hi',
  }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({
    lang: 'ta',
    testimonialText: {
      fontFamily: 'FedraSansStd-book',
      fontSize: '16px',
      fontStyle: 'italic',
      lineHeight: '26px',
      textAlign: 'right',
    },
  }),
  cn: commonLangStyle({ lang: 'cn' }),
  ar: commonLangStyle({
    lang: 'ar',
    testimonialText: {
      fontFamily: 'FedraSansStd-book',
      fontSize: '16px',
      fontStyle: 'italic',
      lineHeight: '26px',
      textAlign: 'right',
    },
  }),
  id: commonLangStyle({ lang: 'id' }),
};
export default TestimonialSt;
