/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { RenderStructuredText } from '@shi/common/SectionUI';

/* Services */

/**
 * Renders the Interested component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} Interested component
 */

const Interested = ({ sectionContent, region, lang, pageData }) => {
  return (
    <Box
      width={{
        sm: '250px',
        base: '250px',
        md: '336px',
      }}
      mx={'auto'}
      padding={{
        base: '30px 0px',
        sm: '30px 0px',
        md: '50px 0px',
      }}
    >
      <RenderStructuredText
        sectionContent={sectionContent[0]}
        pageData={pageData}
        region={region}
        lang={lang}
      />
    </Box>
  );
};

export default Interested;
