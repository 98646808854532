import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h1: {
      color: '#ffffff',
      fontSize: { base: '28px', sm: '28px', md: '36px' },
      lineHeight: { base: '37px', sm: '37px', lg: '43.2px' },
      mx: { base: 'auto', md: 'auto' },
      fontSize: { base: '30px', sm: '30px', md: '30px', lg: '36px' },
      width: { base: '275px', sm: '275px', lg: '325px' },
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-A-medium',
    },
    p: {
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-A-medium',
      fontSize: {
        base: '20px',
        sm: '20px',
        xl: '30px',
      },
    },
    smallText: {
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-A-medium',
      fontSize: {
        base: '16px',
        sm: '16px',
        xl: '24px',
      },
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const UpcomingShivangaSpurthiSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({
    lang: 'hi',
  }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  cn: commonLangStyle({ lang: 'cn' }),
  ar: commonLangStyle({
    lang: 'ar',
  }),
  id: commonLangStyle({ lang: 'id' }),
};
export default UpcomingShivangaSpurthiSt;
