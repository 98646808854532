import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    cardIdText: {
      fontFamily: isIndian ? config.isoLangFont[lang] : fontFamily,
      textAlign: 'center',
      // fontWeight: 600,
      lineHeight: { lg: '30px' },
      fontSize: { base: '16px', md: '20px' },
      color: '#28231E',
    },
    price: {
      fontFamily: isIndian ? config.isoLangFont[lang] : fontFamily,
      color: '#CA4E2A',
      // fontWeight: 600,
      fontSize: { base: '32px', md: '54px' },
      lineHeight: { lg: '54px' },
    },
    cardIdDetails: {
      fontWeight: 400,
      fontSize: { base: '16px', md: '18px' },
      lineHeight: { base: '20px', md: '26px' },
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-book',
      color: '#28231E',
    },
  };
  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const ShivangaRegistrationSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  ar: commonLangStyle({ lang: 'ar' }),
  id: commonLangStyle({ lang: 'id' }),
};

export default ShivangaRegistrationSt;
