/* Built In Imports */

/* External Imports */
import { Box, Center, Image } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@shi/Utility/StructuredTextParser';
import DonateCard from '@shi/components/card/DonateCard';
import SectionTitle from '@shi/components/headings/SectionTitle';
import DonateSt from '@shi/styleData/DonateSt';

/* Services */

/**
 * Renders the ContactUs component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} Donate component
 */

const Donate = ({ sectionContent, region, lang }) => {
  // console.log("the donate is", sectionContent)
  return (
    <Box
      // bgImage={{
      //   base: `url(${sectionContent[4]?.mobileImage?.url})`,
      //   sm: `url(${sectionContent[4]?.mobileImage?.url})`,
      //   md: `url(${sectionContent[4]?.image?.url})`,
      // }}
      bgImage={{
        base: `linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 60%, rgba(0,0,0,0.6) 100%), url(${sectionContent[4]?.mobileImage?.url})`,
        sm: `linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 60%, rgba(0,0,0,0.6) 100%), url(${sectionContent[4]?.mobileImage?.url})`,
        md: `linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 60%, rgba(0,0,0,0.6) 100%), url(${sectionContent[4]?.image?.url})`,
      }}
      bgSize="cover"
      bgPos="center"
      bgRepeat="no-repeat"
      paddingTop={{
        base: '50px',
        sm: '50px',
        md: '45px',
        lg: '45px',
      }}
      paddingBottom={{
        base: '50px',
        sm: '50px',
        md: '81px',
        lg: '81px',
      }}
    >
      <Box>
        <Center>
          <SectionTitle
            titleObj={sectionContent[0]}
            titleDecoration={sectionContent[0]?.titleDecoration}
            {...DonateSt[lang].h1}
          />
        </Center>
        <Box
          display={'flex'}
          flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
          justifyContent={'center'}
          alignItems={'center'}
          gap={'50px'}
          mt={{ base: '21px', sm: '21px', md: '30px' }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            width={{ base: '289px', sm: '289px', md: '289px' }}
            justifyContent={'center'}
            alignItems={'center'}
            fontFamily={'Open Sans'}
          >
            <Box>
              <StructuredTextParser
                str={render(sectionContent[1].body)}
                theme="light"
                region={region}
                lang={lang}
                {...DonateSt[lang].p}
                fontSize="20px"
              />
            </Box>
            <Box marginTop={{ base: '18px', sm: '18px', md: '32px' }}>
              <Image
                src={sectionContent[2]?.image?.url}
                alt={sectionContent[2]?.image?.alt}
                title={sectionContent[2]?.image?.title}
              />
            </Box>
          </Box>
          <DonateCard
            sectionContent={sectionContent[3]}
            region={region}
            lang={lang}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Donate;
