import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h3: {
      color: '#28231E',
      fontFamily: isIndian ? config.isoLangFont[lang] : fontFamily,
      fontSize: '22px',
      fontStyle: 'normal',
    },
    description: {
      color: '#28231E',
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-book',
      fontSize: '15px',
      fontStyle: 'normal',
      lineHeight: 'normal',
    },
    solidButton: {
      color: '#FFF',
      fontFamily: isIndian ? config.isoLangFont[lang] : fontFamily,
      fontSize: { base: 12, md: 14 },
      fontStyle: 'normal',
    },
  };
  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const CardProgram3St = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  ar: commonLangStyle({
    lang: 'ar',
  }),
  id: commonLangStyle({ lang: 'id' }),
};

export default CardProgram3St;
