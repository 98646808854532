/* Built In Imports */

/* External Imports */
import { Box, Stack, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import StructuredTextContent from '@components/UI/StructuredTextContent';
import SimpleButton from '@shi/components/button/SimpleButton';
import SectionTitle from '@shi/components/headings/SectionTitle';
import ShivangaRegistrationSt from '@shi/styleData/ShivangaRegistrationSt';

/* Services */

/**
 * Renders the ShivangaPricing component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} ShivangaPricing component
 */

const ShivangaPricing = ({ sectionContent, lang }) => {

  return (
    <Box p="40px 0 30px" w={{ base: '100%', md: '100%' }} background="#02093C">
      <Box w={'100 %'} spacing={10} align="center" justify="center">
        {sectionContent?.map((secCon, index) => {
          if (secCon._modelApiKey === 'section_title') {
            return <SectionTitle className="pricing-title" titleObj={secCon} key={index} />;
          }
        })}

        <Box
          m={{ base: '35px 16px', md: '30px 0' }}
          p={{ base: '30px 0px', md: '50px 0' }}
          w={{ base: '92%', md: '707px' }}
          background="#FFFFFF"
          className="txt"
        >
          <Stack spacing={2} align="center">
            {sectionContent?.map((secCon, index) => {
              if (secCon.fieldId === 'pricing') {
                return (
                  <Text
                    mt={{ base: '20px', md: '0' }}
                    w={{ base: '170px', md: '100%' }}
                    mb="10px"
                    {...ShivangaRegistrationSt[lang].cardIdText}
                  >
                    {StructuredTextContent(secCon.body)}
                  </Text>
                );
              } else if (secCon.fieldId === 'price') {
                return (
                  <Text
                    fontFamily="FedraSansStd-medium"
                    pb="10px"
                    textAlign="center"
                    color="#CA4E2A"
                    // fontWeight={600}
                    fontSize={{ base: '32px', sm: '48px' }}
                    {...ShivangaRegistrationSt[lang].price}
                  >
                    {StructuredTextContent(secCon.body)}
                  </Text>
                );
              } else if (secCon.fieldId === 'includes') {
                return (
                  <Text
                    fontFamily="FedraSansStd-book"
                    textAlign="center"
                    maxW={{ base: '90%', md: '397px' }}
                    {...ShivangaRegistrationSt[lang].cardIdDetails}
                  >
                    {StructuredTextContent(secCon.body)}
                  </Text>
                );
              } else if (secCon.fieldId === 'purchase') {
                return (
                  <Text
                    fontFamily="FedraSansStd-book"
                    textAlign="center"
                    maxW={{ base: '90%', md: '397px' }}
                    {...ShivangaRegistrationSt[lang].cardIdDetails}
                  >
                    {StructuredTextContent(secCon.body)}
                  </Text>
                );
              }
            })}
          </Stack>
        </Box>

        {sectionContent?.map((secCon, index) => {
          if (secCon._modelApiKey === 'simple_button') {
            return <SimpleButton style={secCon} minW="243px" />;
          }
        })}
        <style global jsx>{`
          .regn-txt p {
            font-family: FedraSansStd-book !important;
          }
          .txt a {
            color: #ca4e2a;
            text-decoration: underline;
          }
          .txt mark {
            color: #af1f22;
            background: none;
          }
        `}</style>
      </Box>
    </Box>
  );
};

export default ShivangaPricing;
