import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h1: {
      color: '#ffffff',
      fontSize: { base: '28px', sm: '28px', md: '36px' },
      lineHeight: { base: '37px', sm: '37px', lg: '43.2px' },
      mx: { base: '17px', md: 'auto' },
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-A-medium',
    },
    p: {
      textAlign: 'center',
      fontWeight: 400,
      fontSize: { base: '18px', sm: '18px', xl: '20px !important' },
      lineHeight: { base: '28px', sm: '28px', xl: '30px' },
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-book',
    },
    title: {
      color: '#28231E',
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-A-medium',
      // fontWeight: 700,
      fontSize: { base: '24px', sm: '24px', xl: '24px' },
      lineHeight: { base: '30px', sm: '30px', xl: '30px' },
    },
    descriptionText: {
      color: '#28231E',
      fontWeight: '400',
      fontSize: {
        base: '14px',
        sm: '14px',
        xl: '14px',
      },
      lineHeight: { base: '20px', sm: '20px', xl: '24px' },
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-book',
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const DonateSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({
    lang: 'hi',
  }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  cn: commonLangStyle({ lang: 'cn' }),
  ar: commonLangStyle({
    lang: 'ar',
  }),
  id: commonLangStyle({ lang: 'id' }),
};
export default DonateSt;
