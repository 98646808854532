/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@shi/Utility/StructuredTextParser';
import MidContainer from '@shi/components/Layout/MidContainer';
import SectionTitle from '@shi/components/headings/SectionTitle';

/* Services */

/**
 * Renders the History component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} History component
 */

const History = ({ sectionContent, lang, pageData, region }) => {
  return (
    <Box
      background={'#02093C'}
      padding={{
        base: '52px 0px',
        sm: '52px 0px',
        md: '111px 0px',
      }}
    >
      <SectionTitle titleObj={sectionContent[0]} />
      <MidContainer
        w={{
          base: '298px',
          sm: '298px',
          md: '670px',
        }}
        pt="15px"
      >
        <StructuredTextParser
          theme="light"
          pageData={pageData}
          fontSize="16px"
          region={region}
          lang={lang}
          sx={{ p: { fontSize: '16px' } }}
          str={render(sectionContent[1].body)}
        />
      </MidContainer>
    </Box>
  );
};

export default History;
