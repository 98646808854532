/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Flex, Hide, Icon, Show } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';
import YouTube from 'react-youtube';

/* Internal Imports */
/* Components */
import MidContainer from '@shi/components/Layout/MidContainer';
import { getEmbedUrl } from '@shi/components/Utility/utils';

/* Services */
import { isInViewport } from '@shi/Utility/Shared/SharedService';

/* Styles */
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

/**
 * Renders MediaEmbed component
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data for Section
 * @param sectionContent.margin
 * @param sectionContent.width
 * @param sectionContent.pxBase
 * @returns {ReactElement} MediaEmbed component
 */

const MediaEmbedV2 = ({ sectionContent, margin, width, lang, ...props }) => {
  const [vidVis, setVidVis] = useState(false);
  const [opts, setOpts] = useState({});
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  useEffect(() => {
    setOpts({
      height: isMobile ? '200px' : '420px',
      //height: '315',
      width: '100%',
      playerVars: {
        autoplay: 1,
      },
    });
  }, [global?.window, isMobile]);

  useEffect(() => {
    if (props.autoplay) {
      window.addEventListener('scroll', listenToScroll);
    }
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const listenToScroll = () => {
    const scrollDiv = document.getElementById(props['sectionId']);
    const inViewPort = isInViewport(scrollDiv);
    if (!vidVis && inViewPort) {
      setVidVis(true);
    }

    // console.log('sdsdsd', props['sectionId'], inViewPort);
    // const heightToHideFrom = window.innerHeight / 1.5;
    // const winScroll = document.body.scrollTop ||
    //   document.documentElement.scrollTop;

    // if (winScroll > heightToHideFrom) {
    //   setVidVis(true);
    // }
  };

  const onReady = event => {
    event.target.playVideo();
  };

  const playerOverlay = (
    <Flex
      position="relative"
      top={0}
      bottom={0}
      left={0}
      right={0}
      justifyContent="center"
      alignItems="center"
      display={vidVis ? 'none' : 'flex'}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        w={{ base: '48px', md: '84px' }}
        h={{ base: '48px', md: '84px' }}
        rounded={props.round || 'full'}
        background={props.bgcolor || '#FFAB43'}
        _hover={{
          background: '#E19502',
        }}
        // _hover={{
        //   boxShadow: ' -4px 14px 22px -5px rgba(0,0,0,0.66)',
        // }}
        textAlign="center"
        fontSize={{ base: '15px', md: '30px' }}
        onClick={e => setVidVis(!vidVis)}
        display={vidVis ? 'none' : 'flex'}
        color={props.txtcolor || ''}
      >
        <Hide below="md">
          <Icon width="18px" height="22px" viewBox="0 0 18px 22px" fill="none">
            <path
              d="M0.811357 11.004C0.811357 8.31495 0.824507 5.62373 0.811357 2.93469C0.811357 1.86959 1.20583 1.04338 2.25339 0.572194C3.30095 0.10101 4.20826 0.337697 5.08488 0.975439C8.77107 3.6579 12.4638 6.33452 16.1632 9.00529C17.7389 10.1493 17.7433 11.8543 16.1632 12.9939C12.4726 15.6749 8.77984 18.3515 5.08488 21.0238C4.18854 21.6812 3.24178 21.9004 2.1986 21.4051C1.15542 20.9098 0.798202 20.1121 0.804776 19.0733C0.8245 16.3843 0.811357 13.693 0.811357 11.004Z"
              fill="#0C0C0C"
            />
          </Icon>
        </Hide>
        <Show below="md">
          <Icon width="10px" height="14px" viewBox="0 0 10px 14px" fill="none">
            <path
              d="M0.464749 7.00291C0.464749 5.46624 0.472263 3.92833 0.464749 2.39167C0.464749 1.78301 0.690171 1.31087 1.2888 1.04161C1.88744 0.772349 2.40593 0.907605 2.90688 1.27205C5.01337 2.80495 7.12361 4.33452 9.23762 5.86074C10.1381 6.51448 10.1406 7.48883 9.23762 8.14006C7.12862 9.67213 5.01837 11.2017 2.90688 12.7288C2.39465 13.1045 1.85362 13.2297 1.2575 12.9467C0.661366 12.6636 0.457231 12.2078 0.460988 11.6141C0.47226 10.0775 0.464749 8.53957 0.464749 7.00291Z"
              fill="#0C0C0C"
            />
          </Icon>
        </Show>
      </Flex>
    </Flex>
  );

  if (
    sectionContent?.thumbnailDesktopImage?.url ||
    sectionContent?.video?.thumbnailUrl
  ) {
    return (
      <MidContainer w={width || '762'} h="100%" background="black">
        <Box
          backgroundImage={
            !vidVis
              ? sectionContent?.thumbnailDesktopImage?.url ||
              sectionContent?.video?.thumbnailUrl
              : 'none'
          }
          bgSize="contain"
          backgroundSize="100%"
          // backgroundColor="#000"
          backgroundRepeat="no-repeat"
          backgroundPosition={
            sectionContent.id === '170001416' ? 'left' : 'center'
          }
          width="100%"
          position="relative"
          display="flex"
          // boxShadow="0px 31px 20px -13px rgba(0,0,0,0.47)"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
          cursor="pointer"
          // onClick={e => setVidVis(!vidVis)}

          top="0px"
          borderRadius={props.round || { base: '0px', md: 'none' }}
          height={{ base: '193px', sm: '200px', md: '400px' }}
          m={margin || '0 auto 30px auto'}
          id="embed-vid"
          // boxShadow="0px 13px 13px -8px rgba(0,0,0,0.75)"
          {...props}
        >
          {playerOverlay}

          {vidVis && (
            <Box maxW="100%" w="full" height={{ base: '200px', md: '390px' }}>
              <YouTube
                videoId={getEmbedUrl(sectionContent?.video?.url)}
                onReady={onReady}
                opts={opts}
                loading="eager"
              />{' '}
            </Box>
          )}
        </Box>
      </MidContainer>
    );
  }

  return (
    <MidContainer w={width || '696px'}>
      <Box
        backgroundImage={sectionContent?.video?.thumbnailUrl}
        backgroundSize="cover"
        backgroundColor="#000"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        width="100%"
        position="relative"
        boxShadow="0px 13px 13px -8px rgba(0,0,0,0.75)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        cursor="pointer"
        onClick={() => setVidVis(!vidVis)}
        overflow="hidden"
        height={{ base: '300px', md: '430px' }}
        m={margin || '0 auto 30px auto'}
        id="embed-vid"
      >
        {vidVis && (
          <Box maxW="100%" w="full">
            <YouTube
              videoId={getEmbedUrl(sectionContent?.video?.url)}
              onReady={onReady}
              opts={opts}
              loading="lazy"
            />{' '}
          </Box>
        )}
      </Box>
    </MidContainer>
  );
};

export default MediaEmbedV2;
