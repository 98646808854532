/* Built In Imports */

/* External Imports */
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import TestimonialSt from '@shi/styleData/TestimonialSt';

/* Services */
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';

/**
 * Renders the Testimonials component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} Testimonials component
 */

const Testimonials = ({ sectionContent, region, lang }) => {
  const testimonial = sectionContent?.body?.[0]?.value?.find(
    el => el?.sectionId == 'testimonial'
  );
  // const testimonialData = testimonial?.sectionContent;
  const testimonialData = sectionContent;
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + '</span>';
    },
  };
  const iconSize = { base: '25px', md: '25px' };

  return (
    <>
      <Box
        background="#FFEBC7"
        p={{ base: '30px 16px', md: '30px 16px' }}>
        <Box
          width="100%"
          maxW={{ base: '600px', lg: '900px', xl: '1150px' }}
          mx="auto"
          display="flex"
        >
          <Box w="100%" pos="relative">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              pagination={pagination}
              breakpoints={{
                1200: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
              }}
              modules={[Pagination, Navigation, Autoplay]}
              navigation={{ nextEl: '.test-right', prevEl: '.test-left' }}
              className="testSwiper"
            >
              {testimonialData?.[0]?.testimonials?.map((el, i) => {
                return (
                  <SwiperSlide key={nanoid()}>
                    <Box
                      background="#fff"
                      padding={{ base: '30px 16px', md: '35px 36px' }}
                      display="flex"
                      w="100%"
                      h="auto"
                      minH={{ base: '400px', md: '248px' }}
                      flexDirection={{ base: 'column', md: 'row' }}
                      fontFamily="FedraSansStd-book"
                      fontSize="16px"
                      alignSelf={{ base: 'center', sm: 'unset' }}
                      key={i}
                    >
                      <Box
                        m={{ base: '0 20px 0 0', lg: '0 27px 0 0' }}
                        display="flex"
                        minWidth="120px"
                        height={120}
                        justifyContent={'center'}
                      >
                        <LazyLoadImageComponent
                          loading="lazy"
                          src={el?.photo?.url}
                          title={el?.photo?.title}
                          alt={el?.photo?.alt}
                          borderRadius="50%"
                          width="120px"
                          height={120}
                          background="lightgray 50% / cover no-repeat"
                        />
                      </Box>
                      <Box
                        color="#5A5A5A"
                        display="block"
                        mt={{ base: '20px', md: '0' }}
                      >
                        <Text {...TestimonialSt[lang].testimonialText}>
                          {el?.testimonialText}
                        </Text>
                        <Text
                          color="#28231E"
                          fontFamily="FedraSansStd-book"
                          m={{ base: '18px 0 0 0' }}
                        >
                          <Text
                            fontWeight={'600'}
                            fontSize="18px"
                            {...TestimonialSt[lang].testimonialName}
                          >
                            {el?.userName}
                          </Text>
                          <Text
                            as="span"
                            color="#AF1F22"
                            fontSize="14px"
                            {...TestimonialSt[lang].testimonialDes}
                          >
                            {el?.userDescription}
                          </Text>
                        </Text>
                      </Box>
                    </Box>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            {/* <Hide below="md"> */}
            {testimonialData?.[0]?.testimonials.length > 2 && (
              <Flex
                pos="absolute"
                width={{ base: '96%', md: '100%' }}
                maxW="100%"
                // overflow={{ base: 'hidden', md: 'visible' }}
                top={{ base: '20%', md: '33%' }}
                left={{ base: '2%', md: '0%' }}
                zIndex="4"
                justifyContent="space-between"
                flexDir="row-reverse"
              // transform="translate(-50%, -50%)"
              >
                <Button
                  className="test-right arrow"
                  rounded="full"
                  height={{ base: '40px', md: '60px' }}
                  width={{ base: '40px', md: '60px' }}
                  mr="-20px"
                  _focus={{ boxShadow: 'none' }}
                  color="#000"
                  background="#fff"
                  _hover={{ bg: '#E19502' }}
                  filter="drop-shadow(1.33px 1.288px 3.148px rgba(0, 0, 0, 0.02)) drop-shadow(5.852px 5.669px 6.519px rgba(0, 0, 0, 0.04)) drop-shadow(14.365px 13.916px 13px rgba(0, 0, 0, 0.05)) drop-shadow(27.666px 26.8px 25.481px rgba(0, 0, 0, 0.06)) drop-shadow(46.554px 45.097px 46.852px rgba(0, 0, 0, 0.08)) drop-shadow(71.825px 69.578px 80px rgba(0, 0, 0, 0.10))"
                >
                  <ChevronRightIcon fontSize={iconSize} />
                </Button>
                <Button
                  className="test-left arrow"
                  rounded="full"
                  height={{ base: '40px', md: '60px' }}
                  width={{ base: '40px', md: '60px' }}
                  ml="-20px"
                  _focus={{ boxShadow: 'none' }}
                  color="#000"
                  background="#fff"
                  _hover={{ bg: '#E19502' }}
                  filter="drop-shadow(1.33px 1.288px 3.148px rgba(0, 0, 0, 0.02)) drop-shadow(5.852px 5.669px 6.519px rgba(0, 0, 0, 0.04)) drop-shadow(14.365px 13.916px 13px rgba(0, 0, 0, 0.05)) drop-shadow(27.666px 26.8px 25.481px rgba(0, 0, 0, 0.06)) drop-shadow(46.554px 45.097px 46.852px rgba(0, 0, 0, 0.08)) drop-shadow(71.825px 69.578px 80px rgba(0, 0, 0, 0.10))"
                >
                  <ChevronLeftIcon fontSize={iconSize} />
                </Button>
              </Flex>
            )}
          </Box>
        </Box>
        <style>
          {`
          .swiper{
            padding-bottom:40px;
          }
          .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
            bottom:0px;
          }
             .swiper-pagination-bullet {
              width: 10px;
              height: 10px;
              color: #000;
              opacity: 1;
              background: #D2BF8F;
            }
             .swiper-pagination-bullet-active {
              color: #fff;
              background: #02093C;
            }
            .swiper-button-disabled{
              visibility: hidden;
            }
            `}
        </style>
      </Box>
    </>
  );
};

export default Testimonials;
